// eslint-disable-next-line @typescript-eslint/no-var-requires, import/no-absolute-path
const CookiesCustomConfig: CookiesCustomConfig = require('/public/Cookies.config.json')

/*
 CookiesCustomConfig:
 {
  "CustomAPIURL": "",
  "CusomAPIs": {
    "APINAme": {
      "Path": "",
      "FullPath": "",
      "Prefix": ""
    }
  }
 }
 */

const API_MAPPER :APIMAPPER = {
  // ---------
  // ==================================================================== GENERAL APIs
  Systems: 'Systems',
  // ---------
  Config: 'Config',
  // ----------------------------------------------------Categories
  Categories: 'Categories',
  // ----------------------------------------------------Branches
  Companies: 'Companies',
  // ----------------------------------------------------Branches
  Branches: 'Branches',
  // ----------------------------------------------------
  // ----------------------------------------------------Nationalities
  Nationalities: 'Nationalities',
  // ----------------------------------------------------
  // ---------
  // ==================================================================== Dashboard APIs
  DashBoard: 'DashBoard/Main',
  DashBoard_Transfers: 'DashBoard/TransfersCount',
  // ==================================================================== Security APIs
  Authorization: 'Authorization/GetAccess',
  Login: 'Authorization/Login',
  Refresh: 'Authorization/Refresh',
  Logout: 'Authorization/Logout',
  // ----------------------------------------------------Profiles
  Security_Profiles: 'Profiles',
  // ----------------------------------------------------Users
  Security_Users: 'Users',
  Security_People: 'People',
  Security_ChangePassword: 'Users/ChangePassword',
  Security_ChangePasswordByAdmin: 'Users/ChangePasswordByCookiesAdmin',
  // ----------------------------------------------------Roles
  Security_Roles: 'Roles',
  // ---------
  // ----------------------------------------------------Employees
  HR_Employees: 'HR/Employees',
  HR_Departments: 'HR/Departments',
  HR_Deductions: 'HR/Deductions',
  HR_Warnings: 'HR/Warnings',
  HR_WarningType: 'HR/WarningTypes',
  HR_Positions: 'HR/Positions',
  HR_Attendance: 'HR/Attendance',
  HR_PayrollItem: 'HR/PayrollItems',
  HR_PayrollItemTypes: 'HR/PayrollItemTypes',
  HR_Shifts: 'HR/Shifts',
  HR_ShiftItems: 'HR/ShiftItems',
  HR_AttendanceRequest: 'HR/AttendanceRequests',
  HR_Devices: 'HR/Devices',
  HR_Devices_OTP: 'HR/Devices/OTP',
  // ==================================================================== Category APIs
  Catalogs: 'Catalog',
  // ==================================================================== Inventory APIs
  // ----------------------------------------------------Items
  Inventory_Items: 'Items',
  Inventory_Items_MassADD: 'Items/MassCreate',
  // ----------------------------------------------------Warehouses
  Inventory_Warehouses: 'Warehouses',
  // -------------------------------------------------
  // ----------------------------------------------------Warehouses
  Inventory_Sections: 'Sections',
  // ----------------------------------------------------Warehouses
  Inventory_ItemsLocation: 'ItemsLocations',
  // ----------------------------------------------------Transfers
  Inventory_Transfers: 'Transfers',
  Inventory_Transfers_Import: 'Transfers/Import',
  Inventory_Transfers_ImportLocations: 'Transfers/ImportLocations',
  Inventory_Transfers_PartialImport: 'Transfers/PartialImport',
  Inventory_AvailableItems: 'AvailableItems',
  Inventory_AvailableItems_Reports_Items: 'AvailableItems/Reports/Items',
  Inventory_AvailableItems_Reports_Categories: 'AvailableItems/Reports/Categories',
  Inventory_AvailableItems_Reports_Brands: 'AvailableItems/Reports/Brands',
  Inventory_TransferItems: 'TransfersItems',
  Inventory_Transfers_Export: 'Transfers/Export',
  Inventory_Transfers_BranchesTransfer: 'Transfers/BranchesTransfer',
  Inventory_Transfers_Internal: 'Transfers/InternalTransfer',
  Inventory_Transfers_UPDATE: 'Transfers/Update',
  Inventory_Transfers_InvoiceNO: 'Transfers/InvoiceNO',
  Inventory_Transfers_Reports_ItemsExports: 'Transfers/Reports/ItemsExports',
  Inventory_Transfers_Reports_CategoriesExports: 'Transfers/Reports/CategoriesExports',
  Inventory_Transfers_Reports_BrandsExports: 'Transfers/Reports/BrandsExports',
  // ----------------------------------------------------Units
  Inventory_Units: 'Units',
  // ----------------------------------------------------Categories
  Inventory_Categories: 'Categories',
  // ==================================================================== Sales APIs
  // ----------------------------------------------------Sales
  Sales_Orders: 'Sales/Orders',
  // ==================================================================== CRM APIs
  // ----------------------------------------------------Customers
  CRM_Customers: 'CRM/Customers',
  // ----------------------------------------------------Customer Actions
  CRM_Customers_Actions: 'Customers/Actions',
  // ----------------------------------------------------Customer Visits
  CRM_Customers_Visits: 'Customers/Visits',
  // ==================================================================== Purchase APIs
  // ----------------------------------------------------Customers
  Purchase_Orders: 'Sales/Orders',
  // ----------------------------------------------------Customer Actions
  Purchase_Vendors: 'CRM/Customers',
  // ==================================================================== MRP APIs
  // ----------------------------------------------------Manufacturers
  MRP_Manufacturers: 'Manufacturers',
  // ----------------------------------------------------Manufacturing Orders
  MRP_ManufacturingOrders: 'ManufacturingOrders',
  // ----------------------------------------------------Suppliers
  MRP_Suppliers: 'Suppliers',
  // ==================================================================== Finance APIs
  // ----------------------------------------------------Transactions
  Finance_Transactions: 'Finance/Transactions',
  // ----------------------------------------------------Accounts
  Finance_Accounts: 'Finance/Accounts',
  // ----------------------------------------------------Invoices
  Finance_Invoices: 'Invoices',
  // ----------------------------------------------------Taxes
  Finance_Taxes: 'Taxes',
  // ==================================================================== Notifications APIs
  Notification_History: 'NotificationHistory'
}

export const APIMapper = {
  computed: {
    API_URL () {
      if (window.location.origin.indexOf('http://localhost:') === -1) {
        return window.location.origin + '/'
      }
      // ////// LIVE ////////////////////////////////////////////
      // return 'https://94.127.214.84:8089/' // Delivery
      // return 'https://94.127.214.84:8091/' // testing
      // return 'https://94.127.214.84:9011/' // Main Dev
      // return 'https://94.127.214.84:9011/securitySystem/' // Security Dev
      // return 'https://103.125.216.197:9011/' // Security Dev
      // return 'https://146.71.78.183:9011/' // vpsserver
      // return 'http://localhost:9893/' // LocalExpressJS
      // return 'https://saedy.cookiesbis.com/' // Saedy Live
      // return 'https://ayla-system.cookiesbis.com/' // ayla-system Live
      // return 'https://saedy-hr.cookiesbis.com/' // SaedyHRRR Live
      // return 'http://137.184.25.226:9762/' // ExpressJS Test
      // return 'https://inventory.cookiesbis.com/' // Saedy Test
      // return 'https://catalogs.cookiesbis.com/api/' // <3,
      // return 'https://165.232.123.252:8085/' // SaedyCatalog,
      // return 'http://165.232.123.252:9894/' // SaedyCore,
      // return 'https://165.232.123.252:8087/' // AylaCatalog

      // ////// TESTING ////////////////////////////////////////////
      // return 'https://161.97.94.220:8085/' // New .netcore
      // return 'https://testing1.cookiesbis.com/' // tesing1
      // return 'http://212.227.241.227:9893/' // nodejs test
      return 'https://saedy-hr.cookiesbis.com/' // Saedy HR Live
    },
    API_MAPPER () :APIMAPPER {
      console.log(CookiesCustomConfig)
      var Mapper :any = {}
      const ApiMapper :any = API_MAPPER
      var Prefix :any = 'API/'
      var GlobalAPIURL :any = this.API_URL
      if (CookiesCustomConfig?.CustomAPIPrefix) {
        Prefix = CookiesCustomConfig?.CustomAPIPrefix
      }
      if (CookiesCustomConfig?.CustomAPIURL) {
        GlobalAPIURL = CookiesCustomConfig?.CustomAPIURL
      }
      Object.keys(API_MAPPER).forEach((value) => {
        if (CookiesCustomConfig?.CustomAPIs && CookiesCustomConfig.CustomAPIs[value]?.FullURL) {
          Mapper[value] = CookiesCustomConfig.CustomAPIs[value]?.FullURL
          return
        }
        var APIPrefix :any = Prefix
        var APIURL :any = GlobalAPIURL
        if (CookiesCustomConfig?.CustomAPIs && (CookiesCustomConfig.CustomAPIs[value]?.APIURL || CookiesCustomConfig.CustomAPIs[value]?.APIURL === '')) {
          APIURL = CookiesCustomConfig.CustomAPIs[value]?.APIURL
        }
        if (CookiesCustomConfig?.CustomAPIs && (CookiesCustomConfig.CustomAPIs[value]?.Prefix || CookiesCustomConfig.CustomAPIs[value]?.Prefix === '')) {
          APIPrefix = CookiesCustomConfig.CustomAPIs[value]?.Prefix
        }
        if (CookiesCustomConfig?.CustomAPIs && CookiesCustomConfig.CustomAPIs[value]?.APIPath) {
          Mapper[value] = APIURL + APIPrefix + CookiesCustomConfig?.CustomAPIs[value]?.APIPath
          return
        }
        Mapper[value] = APIURL + APIPrefix + ApiMapper[value] as any
      })
      return Mapper
    }
  }
}
