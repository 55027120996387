import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/buttons/DownArrow.svg'


const _withScopeId = n => (_pushScopeId("data-v-5d7a8a5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 2,
  class: "F_75em"
}
const _hoisted_3 = {
  key: 3,
  class: "F_75em"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "ImageList"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "CookiesSelectHead" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!

  return (_openBlock(), _createElementBlock("div", {
    onFocusout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Opened = false)),
    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Opened = false)),
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Opened = !_ctx.Opened)),
    style: _normalizeStyle([_ctx.StyledOut, _ctx.TextAlign, _ctx.Opened? ' z-index: 30;': '']),
    class: _normalizeClass([_ctx.Disabled ? 'DropDownDisabled' : '', "CookiesSelectBotton"])
  }, [
    ((_ctx.Icons.length !== 0 && _ctx.SelectedImage !== ''))
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "SelectedIcon",
          src: _ctx.SelectedImage
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    ((_ctx.CookiesIcons.length !== 0 ) && _ctx.SelectedIcon !== '')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "SelectedIcon",
          style: _normalizeStyle(_ctx.$store.getters.Direction === 'ltr' ? 'order: 10;' : '')
        }, [
          _createVNode(_component_CookiesIcon, {
            class: "Icons",
            ID: (_ctx.SelectedIcon as IconsIDs)
          }, null, 8, ["ID"])
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.Name !== '')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.Name + '('), 1))
      : _createCommentVNode("", true),
    _createElementVNode("strong", null, _toDisplayString(_ctx.Translate(_ctx.SelectedName)), 1),
    (_ctx.Name !== '')
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(')')))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      style: _normalizeStyle([{background: _ctx.StyledOut.background, 'max-height': _ctx.MaxHeight}, _ctx.TextAlign]),
      class: _normalizeClass(["CookiesSelectMenu", _ctx.Opened ? 'CookiesSelectMenuOpened': ''])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ALLOptions, (option, Index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: (Index),
          class: _normalizeClass(["CookiesSelectItem", _ctx.HoverDark && 'DarkHover']),
          onClick: ($event: any) => (_ctx.OptionSelected(option, Index, $event))
        }, [
          _createElementVNode("div", {
            style: _normalizeStyle([{}, _ctx.TextAlign]),
            class: "CookiesSelectRow H-100"
          }, [
            ((_ctx.CookiesIcons.length !== 0) && _ctx.ALLCookiesIcons[Index] !== '')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_CookiesIcon, {
                    class: "Icons",
                    ID: _ctx.ALLCookiesIcons[Index]
                  }, null, 8, ["ID"])
                ]))
              : _createCommentVNode("", true),
            ((_ctx.Icons.length !== 0 && _ctx.ALLIcons[Index] !== '' && typeof _ctx.ALLIcons[Index] === 'string'))
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "ImageList",
                  src: _ctx.ALLIcons[Index]
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              (_ctx.Type === 'String')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    style: _normalizeStyle(_ctx.TextAlign)
                  }, _toDisplayString(_ctx.Translate(option)), 5))
                : _createCommentVNode("", true),
              (_ctx.Type === 'Object')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    style: _normalizeStyle(_ctx.TextAlign)
                  }, _toDisplayString(_ctx.Translate(_ctx.DeepFetcher(option, _ctx.SelectorFix))), 5))
                : _createCommentVNode("", true)
            ])
          ], 4)
        ], 10, _hoisted_4))
      }), 128))
    ], 6),
    _createElementVNode("img", {
      class: "CookiesDropDownIndicator",
      style: _normalizeStyle(_ctx.Opened? 'transform: rotate(180deg);' : ''),
      src: _imports_0
    }, null, 4)
  ], 38))
}