
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      warehouses: [
        { Name: 'الرقيم', IDs: '[9,10,11,13,14,15,16,17,18,22,23,24,26,28,29,30,32,33,34,35,36,37,38,45,null]' },
        { Name: 'هنجر الفيبر', IDs: [21] },
        { Name: 'AYLA WOOD', IDs: [31] }
      ]
    }
  },
  computed: {
    LanguagesArray () {
      if (!this.$store.state.BranchManagment.AvailableBranches) return []
      var computedArray :Array<any> = this.$store.state.BranchManagment.AvailableBranches
      return computedArray
    }
  },
  // ---------------------------------------------------
  methods: {
    SaveLang (Branch :any) {
      this.$store.state.CookiesMemory.CurrentWarehouse = Branch
      this.$router.go(-1)
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})
