import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35257f50"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "M-Auto DialogContainer" }
const _hoisted_3 = { class: "DialogBody" }
const _hoisted_4 = { class: "DialogSection FlexColumn" }
const _hoisted_5 = { class: "CookiesDialogFooter" }
const _hoisted_6 = {
  class: "F-Bold",
  style: {"margin":"auto 1em"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogDisabler = _resolveComponent("DialogDisabler")!
  const _component_CookiesNormalInput = _resolveComponent("CookiesNormalInput")!
  const _component_CookiesDescriptionBox = _resolveComponent("CookiesDescriptionBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: 'CookiesDialog_' + _ctx.DialogIndex,
      class: "CookiesDialogdBody"
    }, [
      (_ctx.Saving)
        ? (_openBlock(), _createBlock(_component_DialogDisabler, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.Loading.length > 0)
        ? (_openBlock(), _createBlock(_component_DialogDisabler, { key: 1 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_CookiesNormalInput, {
              Placeholder: _ctx.Translate('Name'),
              CookiesInvalidInput: !(_ctx.MEMRead('D_Purchase_Vendor_Name') && _ctx.MEMRead('D_Purchase_Vendor_Name') !== ''),
              ErrorData: _ctx.ErrorsVisibilty && !(_ctx.MEMRead('D_Purchase_Vendor_Name') && _ctx.MEMRead('D_Purchase_Vendor_Name') !== ''),
              Res: 'D_Purchase_Vendor_Name',
              Default: ''
            }, null, 8, ["Placeholder", "CookiesInvalidInput", "ErrorData"]),
            _createVNode(_component_CookiesNormalInput, {
              type: 'Number',
              Placeholder: _ctx.Translate('Phone Number'),
              CookiesInvalidInput: !(_ctx.MEMRead('D_Purchase_Vendor_PhoneNumber') && _ctx.MEMRead('D_Purchase_Vendor_PhoneNumber') !== ''),
              ErrorData: _ctx.ErrorsVisibilty && !(_ctx.MEMRead('D_Purchase_Vendor_PhoneNumber') && _ctx.MEMRead('D_Purchase_Vendor_PhoneNumber') !== ''),
              Res: 'D_Purchase_Vendor_PhoneNumber',
              Default: ''
            }, null, 8, ["Placeholder", "CookiesInvalidInput", "ErrorData"]),
            _createVNode(_component_CookiesDescriptionBox, {
              Placeholder: _ctx.Translate('Notes'),
              Res: 'D_Purchase_Vendor_Description'
            }, null, 8, ["Placeholder"])
          ])
        ])
      ])
    ], 8, _hoisted_1),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
          style: {"background":"var(--colorr1)"},
          class: "F-Bold CookiesButton DialogFooterButton"
        }, _toDisplayString(_ctx.Translate('Cancel')), 1),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.SaveData && _ctx.SaveData(...args))),
          style: _normalizeStyle('background: ' + _ctx.Theme.UserColors.main),
          class: "F-Bold CookiesButton DialogFooterButton"
        }, _toDisplayString(_ctx.Translate('Save')), 5)
      ])
    ])
  ], 64))
}