
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'

import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/leaflet.js'

export default defineComponent({
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      SavingTable: [] as Array<'Saving'>,
      Saved: undefined as boolean|undefined,
      EditSave: false,
      Loading: [] as Array<'Loading'>,
      LoadingTable: [] as Array<'Loading'>,
      CookiesData: [] as Array<PayrollItemType>,
      Nationalities: [] as Array<any>,
      Departments: [] as Array<Department>,
      Branches: [] as Array<Branch>,
      Positions: [] as Array<Position>,
      Shifts: [] as Array<Shift>,
      OriginalData: {} as any,
      Saving: false,
      ErrorsVisibilty: false,
      markerLatLng: null as any,
      GPSLongitude: null,
      GPSLatitude: null,
      clickedPlace: {
        latitude: null,
        longitude: null,
        address: null,
        countryCode: null
      },
      zoom: 5
    }
  },
  methods: {
    ReadDepartments () {
      this.Loading.push('Loading')
      this.Get('HR_Departments').then(response => {
        this.Departments = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Departments'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBranches () {
      this.Loading.push('Loading')
      this.Get('Branches').then(response => {
        this.Branches = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadPositions () {
      this.Get('HR_Positions').then(response => {
        this.Positions = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Positions'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadShifts () {
      this.Get('HR_Shifts').then(response => {
        this.Shifts = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Shifts'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadNationalities () {
      this.Get('Nationalities').then(response => {
        this.Nationalities = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Nationalities'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'HR_Employees',
        OriginalData: this.OriginalData,
        PatchAPI: 'HR_Employees',
        Editing: this.DialogData?.Editing,
        PatchQuery: '/' + this.DialogData?.Data?.ID
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    ChangePassword () {
      this.OpenDialog('Security_ChangePassword_Dialog', 'Change Password', this.MEMRead('D_Employee_Email')) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    PositionChanged (position :Position) {
      // this.MEMClean('D_PayrollItemType_Table')
      var PositionData = this.MEMRead('D_Empolyee_Position_Value')
      this.MEMWrite('D_Empolyee_Shift_Value', PositionData?.Shift)
      this.MEMWrite('D_Empolyee_Shift', PositionData?.Shift?.Name)
      if (!PositionData) PositionData = {}
      if (!PositionData?.PayrollItems) PositionData.PayrollItems = []
      PositionData.PayrollItems.forEach((item :PayrollItem) => {
        this.MEMWrite(`D_PayrollItemType_Table_selector_${item?.PayrollItemTypeID}`, true)
        if (item?.PayrollItemType?.IsCustomizable) {
          this.MEMWrite(`D_PayrollItemType_Table_Edited_Fixed Value_${item?.PayrollItemTypeID}`, item?.Fixed)
          this.MEMWrite(`D_PayrollItemType_Table_Edited_Percentage_${item?.PayrollItemTypeID}`, item?.Percentage)
        } else {
          this.MEMWrite(`D_PayrollItemType_Table_Edited_Fixed Value_${item?.PayrollItemTypeID}`, item?.PayrollItemType?.Fixed)
          this.MEMWrite(`D_PayrollItemType_Table_Edited_Percentage_${item?.PayrollItemTypeID}`, item?.PayrollItemType?.Percentage)
        }
      })
    },
    ResetPoisitonData () {
      this.MEMClean('D_Empolyee_Shift')
      this.CookiesData.forEach((PayrollItemType) => {
        this.MEMWrite(`D_PayrollItemType_Table_selector_${PayrollItemType.ID}`, false)
        this.MEMWrite(`D_PayrollItemType_Table_Edited_Fixed Value_${PayrollItemType.ID}`, PayrollItemType.Fixed)
        this.MEMWrite(`D_PayrollItemType_Table_Edited_Percentage_${PayrollItemType.ID}`, PayrollItemType.Percentage)
      })
    },
    ReadPayrollItemTypes () {
      this.Loading.push('Loading')
      this.Get('HR_PayrollItemTypes').then(response => {
        this.CookiesData = response
        if (this.DialogData?.Editing) {
          this.Loading.push('Loading')
          this.Get('HR_PayrollItem', '?EmployeeID=' + this.DialogData?.Data?.ID).then(response2 => {
            response2.forEach((item :PayrollItem) => {
              this.MEMWrite(`D_PayrollItemType_Table_selector_${item.PayrollItemTypeID}`, true)
              if (item.PayrollItemType.IsCustomizable) {
                this.MEMWrite(`D_PayrollItemType_Table_Edited_Fixed Value_${item.PayrollItemTypeID}`, item.Fixed)
                this.MEMWrite(`D_PayrollItemType_Table_Edited_Percentage_${item.PayrollItemTypeID}`, item.Percentage)
              }
            })
            this.Loading.pop() // Remove Loading flag
          }, error => {
            this.Loading.pop() // Remove Loading flag
            error.CookiesError = 'Error in reading PayrollItem Type'
            this.OpenDialog('Error_Dialog', 'Error', error)
          })
        }
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading PayrollItem Type'
        this.OpenDialog('Error_Dialog', 'Error', error)
      })
    },
    ReadDialogData () {
      this.ReadPayrollItemTypes()
    },
    handleMapClick (e: { latlng: { lat: any; lng: any } }) {
      const { lat, lng } = e.latlng
      this.GPSLatitude = lat
      this.GPSLongitude = lng
      // Perform reverse geocoding to get place information
      // this.getPlaceInformation(lat, lng)
      this.markerLatLng = [lat, lng]
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Payroll Item Types') + this.DialogData?.Type ? ' ' + this.DialogData?.Type : '',
        CookiesID: 'D_PayrollItemType_Table',
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'Name',
            Sort: 'Name',
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Type',
            Type: 'normal',
            Value: 'Type',
            Sort: 'Type',
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Percentage',
            Type: 'numberInput',
            Value: 'Percentage',
            Sort: 'Percentage',
            Res_ID: 'ID',
            numberInput_Step: 0.01,
            numberInput_Default: 0,
            Unit: this.Translate('%'),
            numberInput_IsPercentage: true,
            DynamicClass: {
              ClassList: 'Disabled',
              FixedValue: 0,
              Operator: 'EQUAL',
              ValuePath: 'IsCustomizable'
            }
          },
          {
            Name: 'Fixed Value',
            Type: 'numberInput',
            Value: 'Fixed',
            Sort: 'Fixed',
            Res_ID: 'ID',
            numberInput_Step: 5,
            numberInput_Default: 0,
            Unit: this.PriceUnit,
            DynamicClass: {
              ClassList: 'Disabled',
              FixedValue: 0,
              Operator: 'EQUAL',
              ValuePath: 'IsCustomizable'
            }
          },
          {
            Name: 'Calendar',
            Type: 'Calendar',
            Value: 'Calendar',
            Sort: 'Calendar',
            Res_ID: 'ID'
          },
          {
            Name: 'selector',
            Type: 'selector',
            Value: 'selector',
            Sort: 'selector',
            Res_ID: 'ID'
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      return Table
    },
    ComputedCheckedPayrollItemTypes () {
      return this.MEMRead('D_PayrollItemType_Table')?.map((checkedItem: PayrollItemType) => {
        var CheckedItemPayrollItem = {
          PayrollItemTypeID: checkedItem.ID
        } as any as PayrollItemType
        if (checkedItem.IsCustomizable) {
          CheckedItemPayrollItem.Percentage = this.MEMRead(`D_PayrollItemType_Table_Edited_Percentage_${checkedItem.ID}`)
          CheckedItemPayrollItem.Fixed = this.MEMRead(`D_PayrollItemType_Table_Edited_Fixed Value_${checkedItem.ID}`)
        }
        return CheckedItemPayrollItem
      })
    },
    CheckGPSLongitude () {
      return this.GPSLongitude
    },
    CheckGPSLatitude () {
      return this.GPSLatitude
    },
    CheckmarkerLatLng () {
      return this.markerLatLng
    },
    DialogMapper () {
      return [
        { Path: 'Name', Res: 'D_Employee_Name' },
        { Path: 'PhoneNumber', Res: 'D_Employee_PhoneNumber' },
        { Path: 'Email', Res: 'D_Employee_Email' },
        { Path: ['Branch', 'Name'], Res: 'D_Empolyee_Branch', ValuePath: 'ID', SavingPath: 'BranchID' },
        { Path: ['Department', 'Name'], Res: 'D_Empolyee_Department', ValuePath: 'ID', SavingPath: 'DepartmentID' },
        { Path: ['Nationality', this.$store.state.CookiesConfig.Language], Res: 'D_Empolyee_Nationality', ValuePath: 'ID', SavingPath: 'NationalityID' },
        { Path: ['Shift', 'Name'], Res: 'D_Empolyee_Shift', ValuePath: 'ID', SavingPath: 'ShiftID' },
        { Path: ['Position', 'Name'], Res: 'D_Empolyee_Position', ValuePath: 'ID', SavingPath: 'PositionID' },
        { Path: 'Salary', Res: 'D_Employee_Salary' },
        { Path: 'Gender', Res: 'D_Employee_Gender' },
        { Path: 'InsuranceNumber', Res: 'D_Employee_InsuranceNumber' },
        { Path: 'NationalNumber', Res: 'D_Employee_NationalNumber' },
        { Path: 'PassportNumber', Res: 'D_Employee_PassportNumber' },
        { Path: 'AccountNumber', Res: 'D_Employee_AccountNumber' },
        { Path: 'AccountName', Res: 'D_Employee_AccountName' },
        { Path: 'AccountType', Res: 'D_Employee_AccountType' },
        { Path: 'WorkPlace', Res: 'D_Employee_WorkPlace' },
        { Path: 'TaxNumber', Res: 'D_Employee_TaxNumber' },
        { Path: 'HireDate', Res: 'D_Employee_HireDate' },
        { Path: 'BirthDate', Res: 'D_Employee_BirthDate' },
        { Path: 'Password', Res: 'D_Employee_Password' },
        { Path: 'CanLogIn', Res: 'D_Employee_CanLogin' },
        { Path: 'IsDaily', Res: 'D_Employee_IsDaily' },
        { Path: 'ImgUrl', Res: 'D_Employee_Image', IsImage: true },
        { Path: 'PayrollItems', Value: this.ComputedCheckedPayrollItemTypes },
        { Path: 'GPSLongitude', Value: this.CheckGPSLongitude },
        { Path: 'GPSLatitude', Value: this.CheckGPSLatitude }
      ] as Array<DialogMapperObject>
    }
  },
  created () {
    if (this.DialogData?.Editing) {
      if (this.DialogData?.Data.GPSLatitude && this.DialogData?.Data.GPSLongitude) this.markerLatLng = [this.DialogData?.Data.GPSLatitude, this.DialogData?.Data.GPSLongitude]
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
    }
  },
  mounted () {
    this.ReadDialogData()
    this.ReadDepartments()
    this.ReadBranches()
    this.ReadPositions()
    this.ReadShifts()
    this.ReadNationalities()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
    if (this.DialogData?.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
      this.Console(this.OriginalData)
    }
  },
  unmounted () {
    this.MEMClean('D_PayrollItemType')
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadDepartments()
        this.ReadBranches()
        this.ReadPositions()
        this.ReadShifts()
        this.ReadNationalities()
      }
    }
  }
})
