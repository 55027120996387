
import CookiesDictionery from '@/Dictionary/CookiesDictionary.csv'
import router from '@/Apps/ALL/router/index'
import { defineComponent } from 'vue'

import exportFromJSON from 'export-from-json'
import chroma from 'chroma-js'
import JSZip from 'jszip'
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff'
// eslint-disable-next-line @typescript-eslint/no-var-requires
var sha1 = require('sha1')
const csvexportType = exportFromJSON.types.xls

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Patcher = require('rfc6902')

// whatsapp api initiator
// eslint-disable-next-line @typescript-eslint/no-var-requires

export default defineComponent({
  data () {
    return {
      Patcher: Patcher,
      Refreshing: false
    }
  },
  setup () {},
  computed: {
    Theme () {
      return this.$store.getters.Theme
    },
    DirectionStyler () {
      if (this.$store.getters.Direction === 'ltr') {
        return {
          first: 'var(--rad3) 0px 0px var(--rad3)',
          last: '0px var(--rad3) var(--rad3) 0px',
          borderLeft: 'solid 0.5px rgba(136, 136, 136, 0.1)',
          borderRight: ''
        }
      } else {
        return {
          first: '0px var(--rad3) var(--rad3) 0px',
          last: 'var(--rad3) 0px 0px var(--rad3)',
          borderLeft: '',
          borderRight: 'solid 0.5px rgba(136, 136, 136, 0.1)'
        }
      }
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    CurrentDate () {
      var From = this.$store.state.CurrentDate.From
      var To = this.$store.state.CurrentDate.To
      return 'DateFrom=' + From + '&DateTo=' + To
    },
    CurrentRoute () {
      return this.$route
    },
    PriceUnit () {
      // return this.Translate(this.$store.state.CookiesConfig.PriceUnit as string)
      return this.$store.state.CompanyManagment?.CurrentCompany?.Currency || this.$store.state.CookiesConfig.PriceUnit
    },
    VolumeUnit () {
      // return this.Translate(this.$store.state.CookiesConfig.VolumeUnit as string)
      return this.$store.state.CookiesConfig.VolumeUnit
    },
    DefaultImage () {
      // if (this.$store.getters.CurrentTheme === 'light') {
      //   return require('@/assets/defaults/DefaultLight.svg')
      // } else {
      //   return require('@/assets/defaults/DefaultDark.svg')
      // }
      return require('@/assets/defaults/DefaultImage.svg')
    },
    ComputedDictionary () :any {
      var ComputedDictionary :any = {}
      CookiesDictionery.forEach((word :any) => {
        ComputedDictionary[word.ENG?.toLowerCase()] = word[this.$store.state.CookiesConfig.Language]
      })
      return ComputedDictionary
    },
    ServerDescription () {
      var Descriptions :any = {
        ARA: 'DescriptionAr',
        ENG: 'Description'
      }
      return Descriptions[this.$store.state.CookiesConfig.Language]
    },
    CurrentBackgroundColor () {
      try {
        var colors = {
          BGGradient: this.$store.getters.Theme.UserColors.Gradient,
          BGThird: this.$store.getters.Theme.UserColors.third,
          BGDimmed: this.$store.getters.Theme.UserColors.Dimmed,
          BG1Fixed: this.$store.getters.Theme.BG1Fixed
        }
        return colors[this.$store.state.CookiesConfig.background]
      } catch (error) {
        return ''
      }
    },
    FillBodyHeight () {
      if (this.$route?.meta.NoHeader) {
        return 'BigBodyNoHeader' + (this.$store.state.UserManagment.Status ? ' BigBodyNoHeaderWithUser' : '')
      }
      return 'BigBody' + (this.$store.state.UserManagment.Status ? ' BigBodyWithUser' : '')
      // return {
      //   WithHeader: 'BigBody' + (this.$store.state.UserManagment.Status ? ' BigBodyWithUser' : ''),
      //   NoHeader: 'BigBodyNoHeader' + (this.$store.state.UserManagment.Status ? ' BigBodyNoHeaderWithUser' : '')
      // }
    },
    RouteQuery () {
      return this.$route.query
    },
    TableHeaderStyler () {
      return this.$store.state.CookiesConfig.Transparent ? '' : this.$store.getters.Theme.BG2
    },
    CopmanyLogo () {
      return this.$store.state.CompanyManagment?.CurrentCompany?.LogoURL || this.$store.state.CookiesConfig.Logo
    }
  },
  methods: {
    Get (API :API_NAME, AddQuery = '') :Promise<any> {
      const _this = this
      this.Console('Get', 'font-size: 4em')
      this.Console(API)
      return new Promise((resolve, reject) => {
        var CurrentCookie = ''
        var SessionJWT = this.SessionRead('CookiesJWT')
        var LocalJWT = this.LocalRead('CookiesJWT')
        this.Console(SessionJWT)
        this.Console(LocalJWT)
        if (LocalJWT !== null) {
          CurrentCookie = LocalJWT.value
          _this.Console(CurrentCookie)
        } else if (SessionJWT !== null) {
          CurrentCookie = SessionJWT.value
        }
        BeginGet()
        function BeginGet () {
          var RequestLink = _this.DeepFetcher(_this.API_MAPPER, API) + _this.AddQueryFixer(AddQuery)
          var AvailableOnTesting = false
          if (_this.DeepFetcher(_this.TestingData, API) !== undefined) {
            AvailableOnTesting = true
          }
          // testing mode /////////////////////////////////////////////////////////////////
          if (_this.Testing && _this.TestingData !== undefined && AvailableOnTesting) {
            try {
              setTimeout(() => {
                var ALLDATA = _this.DeepFetcher(_this.TestingData, API)
                resolve(ALLDATA)
              }, 500 + 1000 * Math.random())
            } catch (error) {
              reject(error)
            }
          // Normal mode /////////////////////////////////////////////////////////////////
          } else {
            const RequestOptions: any = {
              method: 'GET',
              credentials: 'include',
              headers: {
                Authorization: 'Bearer ' + CurrentCookie,
                company: _this.$store.state.CompanyManagment.CurrentCompany?.ID
              }
            }
            _this.Console('Get: ' + RequestLink, 'color: greenyellow; font-size: 1.2em; font-weight: 800; padding:2em 0em')
            fetch(RequestLink, RequestOptions)
              .then(CookiesRES => {
                if (CookiesRES.ok) {
                  _this.Console(CookiesRES, 'color: greenyellow;')
                  CookiesRES.json()
                    .then((CookiesData :CookiesResponse) => {
                      _this.Console(CookiesData, 'color: greenyellow;')
                      if (CookiesData.VueBuildVersion) _this.$store.state.CookiesVueServerVersion = CookiesData.VueBuildVersion
                      if (CookiesData.Status) { // API response
                        if (CookiesData.Result === null) {
                          resolve([])
                        } else {
                          resolve(CookiesData.Result) // to be back
                        }
                      } else {
                        reject(new Error(CookiesData.Description))
                      }
                    })
                } else {
                  if (CookiesRES.status === 401 && !_this.Refreshing) {
                    _this.Refreshing = true
                    _this.RefreshToken().then((RefreshResponse) => {
                      _this.Console('Refreshed')
                      _this.Console(RefreshResponse)
                      CurrentCookie = RefreshResponse
                      _this.Refreshing = false
                      BeginGet()
                    }, error => {
                      _this.Refreshing = false
                      _this.$store.state.UserManagment.Status = false
                      reject(error)
                    })
                  } else if (_this.Refreshing) {
                    setTimeout(() => {
                      DelayedGet()
                    }, 200)
                  } else { reject(CookiesRES) }
                }
                function DelayedGet () {
                  if (LocalJWT !== null) {
                    if (_this.Refreshing) {
                      DelayedGet()
                      return
                    }
                    CurrentCookie = LocalJWT.value
                    _this.Console(CurrentCookie)
                  // } else if (SessionJWT !== null && new Date().getTime() < SessionJWT.exp) {
                  } else if (SessionJWT !== null) {
                    CurrentCookie = SessionJWT.value
                  }
                  console.log('CurrentCookie')
                  console.log(API)
                  console.log(CurrentCookie)
                  if (CurrentCookie) BeginGet()
                }
              })
              .catch((error) => {
                reject(error)
              })
          }
        }
      })
    },
    Post (Method :POSTMethods, API :API_NAME, Data :any, AddQuery = '') {
      const _this = this
      return new Promise((resolve, reject) => {
        var CurrentCookie = ''
        var SessionJWT = this.SessionRead('CookiesJWT')
        var LocalJWT = this.LocalRead('CookiesJWT')
        // if (LocalJWT !== null && (new Date().getTime() < LocalJWT.exp || API === 'Refresh')) {
        if (LocalJWT !== null) {
          CurrentCookie = LocalJWT.value
        // } else if (SessionJWT !== null && (new Date().getTime() < SessionJWT.exp || API === 'Refresh')) {
        } else if (SessionJWT !== null) {
          CurrentCookie = SessionJWT.value
        } else if (API === 'Refresh') {
          _this.RefreshToken().then((RefreshResponse) => {
            _this.Console('Refreshed')
            _this.Console(RefreshResponse)
            CurrentCookie = RefreshResponse
            BeginPost()
          }, error => {
            _this.$store.state.UserManagment.Status = false
            reject(error)
          })
        }
        BeginPost()
        function BeginPost () {
          var RequestLink = _this.DeepFetcher(_this.API_MAPPER, API) + _this.AddQueryFixer(AddQuery)
          var TheBody = []
          TheBody = Data
          // //////////
          const RequestOptions: any = {
            method: Method,
            headers: {
              Accept: 'text/plain',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + CurrentCookie,
              company: _this.$store.state.CompanyManagment.CurrentCompany?.ID
            },
            credentials: 'include',
            body: JSON.stringify(TheBody)
            // agent: httpsAgent
          }
          _this.Console('ffffffffffffffffffffffetch', 'color: orange;')
          _this.Console(_this.API_URL)
          _this.Console(RequestLink)
          _this.Console(Method + ': ' + RequestLink, 'color: orange !important; font-size: 1.2em; font-weight: 800; padding:2em 0em')
          _this.Console(TheBody, 'color: orange;')
          _this.Console(RequestOptions.body, 'color: orange;')
          fetch(RequestLink, RequestOptions)
            .then(CookiesResponse => {
              _this.Console('CookiesResponse')
              _this.Console(CookiesResponse)
              if (CookiesResponse.ok) {
                CookiesResponse.json()
                  .then((CookiesData :CookiesResponse) => {
                    _this.Console(CookiesData)
                    if (CookiesData.Status) {
                      resolve(CookiesData.Result)
                    } else {
                      var Err :any = {}
                      Err.message = CookiesData.Message ? CookiesData.Message : ''
                      Err.statusText = CookiesData.Message ? CookiesData.Message : ''
                      reject(Err)
                    }
                  })
              } else {
                _this.Console(CookiesResponse)
                if (CookiesResponse.status === 401) {
                  _this.RefreshToken().then((RefreshResponse) => {
                    _this.Console('Refreshed')
                    _this.Console(RefreshResponse)
                    CurrentCookie = RefreshResponse
                    BeginPost()
                  }, error => {
                    _this.$store.state.UserManagment.Status = false
                    reject(error)
                  })
                } else { reject(CookiesResponse) }
              }
            })
            .catch(error => reject(error))
        }
      })
    },
    AddQueryFixer (AddQuery :string) {
      var ModifiedQuery = AddQuery
      var IsNotEmpty = AddQuery && AddQuery.indexOf('?') > -1
      if (this.$store.state.BranchManagment.CurrentBranch) {
        try {
          var CurrentBranchID = this.$store.state.BranchManagment.CurrentBranch.ID
        } catch (error) {
          return
        }
        if (IsNotEmpty) {
          ModifiedQuery += (AddQuery.indexOf('BranchID') > -1) ? '' : ('&BranchID=' + CurrentBranchID)
        } else {
          ModifiedQuery += '?BranchID=' + CurrentBranchID
        }
      }
      return ModifiedQuery
    },
    SequentialPost (Method :POSTMethods, API :API_NAME, Data :Array<any>, AddQuery = '', DynamicQuerys ?:Array<DynamicQuery>) {
      return new Promise((resolve, reject) => {
        var NumberOfPosts = Data.length
        var Posted = 0
        if (NumberOfPosts === 0) {
          resolve('Nothing To Post')
        }
        Data.forEach(element => {
          var ComputedQuery = AddQuery
          if (DynamicQuerys) {
            DynamicQuerys.forEach(dynamicQuery => {
              AddQuery.replaceAll(dynamicQuery.Replacer, this.DeepFetcher(element, dynamicQuery.Value))
            })
          }
          this.Post(Method, API, element, ComputedQuery).then(() => {
            AllPostFinishedCheck()
          }, error => {
            reject(error)
          })
        })
        function AllPostFinishedCheck () {
          Posted += 1
          if (Posted === NumberOfPosts) {
            resolve('AllPosted')
          }
        }
      })
    },
    RefreshToken () :Promise<string> {
      this.Console('Refreshing Token', 'color: orange, font-size: 2em;')
      var CurrentBAKERY :string
      var RememberMe :boolean
      var SessionBAKERY = this.SessionRead('CookiesBAKERY')
      var LocalBAKERY = this.LocalRead('CookiesBAKERY')
      if (LocalBAKERY !== null) { // -------------------- Remember me Refresher found
        CurrentBAKERY = LocalBAKERY.value
        RememberMe = true
      } else if (SessionBAKERY !== null) { // ------------ Dont Remember me Refresher found
        RememberMe = false
        CurrentBAKERY = SessionBAKERY.value
      } else { // --------------------------------------- No Refresher found
        this.ConfigMapper([])
        return new Promise<string>((resolve, reject) => { reject(new Error('Singed Out')) })
      }
      return new Promise<string>((resolve, reject) => {
        this.Post('POST', 'Refresh', { RefreshToken: CurrentBAKERY }).then(response => {
          var ExpirationTime = new Date().getTime() + (this.BakingMinitues * 60 * 1000)
          if (RememberMe) {
            this.LocalSave('CookiesJWT', { value: this.DeepFetcher(response, 'AccessToken'), exp: ExpirationTime })
            this.LocalSave('CookiesBAKERY', { value: this.DeepFetcher(response, 'RefreshToken'), exp: ExpirationTime })
          } else {
            this.SessionSave('CookiesJWT', { value: this.DeepFetcher(response, 'AccessToken'), exp: ExpirationTime })
            this.SessionSave('CookiesBAKERY', { value: this.DeepFetcher(response, 'RefreshToken'), exp: ExpirationTime })
          }
          if (resolve(this.DeepFetcher(response, 'AccessToken')) !== undefined) {
            resolve(this.DeepFetcher(response, 'AccessToken'))
          } else {
            reject(new Error('Undefined Response'))
          }
        }, error => {
          if (error.statusText === 'Invalid Refresh Token' || error.statusText === 'Expired Refresh Token') {
            if (RememberMe) {
              this.LocalSave('CookiesJWT', null)
              this.LocalSave('CookiesBAKERY', null)
            } else {
              this.SessionSave('CookiesJWT', null)
              this.SessionSave('CookiesBAKERY', null)
            }
          }
          reject(error)
        })
      })
    },
    PostImage (File: any, folder?: string) {
      return new Promise((resolve, reject) => {
        if (!File || File === '') { resolve({ url: '' }) }
        const RequestData = new FormData()
        RequestData.append('file', File)
        RequestData.append('upload_preset', 'u7zjrai6')
        var Folder = window.location.origin.replaceAll('.', '-').replaceAll('/', '_')
        if (folder && folder !== '') {
          Folder = folder
        }
        RequestData.append('folder', Folder)
        const RequestOptions = {
          method: 'POST',
          body: RequestData
        }
        fetch('https://api.cloudinary.com/v1_1/abudari-com/auto/upload', RequestOptions)
          .then(CookiesResponse => {
            if (CookiesResponse.ok) {
              CookiesResponse.json()
                .then(CookiesData => {
                  resolve(CookiesData)
                })
            } else {
              reject(CookiesResponse)
            }
          })
          .catch(error => reject(error))
      })
    },
    DeleteImage (ImgURL: string) {
      return new Promise((resolve, reject) => {
        if (!ImgURL || ImgURL === '') { resolve('') }
        const RequestData = new FormData()
        var ImageID = (ImgURL.split('/').pop())?.split('.')[0]
        if (!ImageID) {
          resolve('')
          return
        }
        RequestData.append('public_id', ImageID)
        const RequestOptions = {
          method: 'POST',
          body: RequestData
        }
        // https://res.cloudinary.com/abudari-com/image/upload/v1694205527/http:__localhost:8080/ye5j2kv7rjocv1c4ebbg.jpg
        var TS = new Date().getTime()
        // const signature = cloudinary.utils.api_sign_request({ public_id: ImageID, api_key: '545684758454967', timestamp: TS }, 'upXRKiLd0q1aVcBNbTBuFi3EXIY')
        const signature = sha1(`&public_id=${ImageID}&timestamp=${TS}upXRKiLd0q1aVcBNbTBuFi3EXIY`)
        fetch('https://api.cloudinary.com/v1_1/abudari-com/image/destroy?api_key=545684758454967&public_id=' + ImageID + '&signature=' + signature + '&timestamp=' + TS, RequestOptions)
          .then(CookiesResponse => {
            if (CookiesResponse.ok) {
              CookiesResponse.json()
                .then(CookiesData => {
                  resolve(CookiesData)
                })
            } else {
              reject(CookiesResponse)
            }
          })
          .catch(error => reject(error))
      })
    },
    async ReadConfig () {
      return new Promise<void>((resolve) => {
        this.Get('Config').then(response => {
          this.ConfigMapper(response)
          resolve()
        }, error => {
          error.CookiesError = 'Error in reading Config'
          // this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
          resolve()
        })
      })
    },
    ConfigMapper (configArray :Array<ConfigItem>) {
      this.$store.state.CookiesConfig = this.DeepCloner(this.$store.state.CookiesOriginalConfig)
      var ServerConfig = {} as any
      configArray.forEach((element :ConfigItem) => {
        ServerConfig[element.Name] = element.Value
        ServerConfig[element.Name + 'ID'] = element.ID
        this.$store.state.CookiesConfig[element.Name] = element.Value
        this.$store.state.CookiesConfig[element.Name + 'ID'] = element.ID
        // later update
        if (element.Name === 'CategoriesImages') {
          this.$store.state.CookiesConfig[element.Name] = JSON.parse(element.Value)
        }
      })
      if (this.$store.state.CompanyManagment.CurrentCompany) {
        const favicon = document.getElementById('FavIcon') as HTMLLinkElement
        this.$store.state.CookiesConfig.FavIco = this.$store.state.CompanyManagment.CurrentCompany.FavIconURL
        favicon.href = this.$store.state.CompanyManagment.CurrentCompany.FavIconURL
      }
      var LocalLang = this.LocalRead('Language')
      if (LocalLang) {
        this.$store.state.CookiesConfig.Language = LocalLang
      } else if (this.$store.state.CookiesConfig.LanguageUserSelect === 'true') {
        this.OpenDialog('ChangeLanguage', '', {}, { header: false })
      }
      this.LocalSave('CookiesDefaultConfig', ServerConfig)
    },
    Translate (Text ?:string) {
      try {
        var Word = Text?.toLowerCase()
        if (Text === undefined || Word === undefined) return '' // handle undefined variables
        if (this.ComputedDictionary[Word] === undefined) {
          var Words = Text.split(' ')
          var computedword = ''
          Words.forEach(textword => {
            var word = textword?.toLowerCase()
            if (this.ComputedDictionary[word] === undefined) {
              // computedword += ' ' + word
              if (this.$store.getters.Direction === 'ltr') {
                computedword += ' ' + textword
              } else {
                computedword = textword + ' ' + computedword
              }
            } else {
              // computedword += ' ' + this.ComputedDictionary[word]
              if (this.$store.getters.Direction === 'ltr') {
                computedword += ' ' + this.ComputedDictionary[word]
              } else {
                computedword = this.ComputedDictionary[word] + ' ' + computedword
              }
            }
          })
          return computedword
        } else {
          return this.ComputedDictionary[Word]
        }
      } catch (error) {
        return Text
      }
    },
    OpenDialog (DialogName :CookiesDialog, Name :string, Data :DialogData, Options:DialogCustomOptions = {}) {
      var DialogObj :DialogObjInterface = {}
      DialogObj.Content = DialogName
      DialogObj.Name = Name
      DialogObj.Data = Data
      if (Options === undefined || Options === null) {
        DialogObj.Options = {}
      } else {
        DialogObj.Options = Options
      }
      if (DialogObj.Content === 'Error_Dialog') {
        this.Console(DialogObj)
        if (this.Errors) {
          this.$store.state.CookiesDialog.ErrorDialogs.push(DialogObj)
          this.Console(this.$store.state.CookiesDialog.ErrorDialogs)
        }
      } else {
        DialogObj.Num = this.$store.state.CookiesDialog.DialogsCount
        this.$store.state.CookiesDialog.OpenedDialogs.push(DialogObj)
        // const CurrentQuery = JSON.parse(JSON.stringify(this.$router.currentRoute._value.query))
        const CurrentQuery = JSON.parse(JSON.stringify(this.$router.currentRoute.value.query))
        CurrentQuery['Dialog' + this.$store.state.CookiesDialog.DialogsCount] = this.$store.state.CookiesDialog.DialogsCount
        this.$router.push({ query: CurrentQuery })
        this.$store.state.CookiesDialog.DialogsCount += 1
      }
    },
    CloseDialog (DialogIndex :number) {
      const Dialogs = this.$store.state.CookiesDialog.OpenedDialogs
      Dialogs.forEach((element, index) => {
        if (element.Num.toString() === DialogIndex.toString()) {
          this.$store.state.CookiesDialog.OpenedDialogs.splice(index, 1)
        }
      })
      // const CurrentQuery = JSON.parse(JSON.stringify(this.$router.currentRoute._value.query))
      // const CurrentQuery = JSON.parse(JSON.stringify(this.$router.currentRoute.value.query))
      // const DialogsNums = this.$store.state.CookiesDialog.OpenedDialogs.map(function (item) {
      //   return item.Num
      // })
      // Object.keys(CurrentQuery).some(function (attr) {
      //   if (DialogsNums.find(element => element.toString() === CurrentQuery[attr].toString()) === undefined) {
      //     delete CurrentQuery[attr]
      //   }
      // })
      // this.$router.push({ query: CurrentQuery })
    },
    CloseErrorDialog (DialogIndex :any) {
      this.$store.state.CookiesDialog.ErrorDialogs.splice(DialogIndex, 1)
    },
    DecimalFixer (value :any, Decimals = 6) {
      try {
        var DecNumber = +parseFloat(value).toFixed(Decimals)
        return isNaN(DecNumber) ? '' : DecNumber
      } catch (error) {
        return 'N/A'
      }
    },
    CookiesSearcher (obj :any, search :string, Options ?:CookiesSearcherOptions) :boolean {
      var searches :any = search.split(' ')
      const _this = this
      var Found = true
      searches.forEach((searchWord :any) => {
        searchWord = searchWord.trim().replaceAll(' ', '').toLowerCase()
        if (searchWord === '') return
        if (!Found) return
        if (Options && Options.CustomAttribute) {
          if (Options && Options.Exclusive) {
            Found = this.DeepFetcher(obj, Options.CustomAttribute) !== null && this.DeepFetcher(obj, Options.CustomAttribute) !== undefined && (this.DeepFetcher(obj, Options.CustomAttribute).toString().toLowerCase().split(' ').indexOf(searchWord) > -1)
          } else {
            Found = this.DeepFetcher(obj, Options.CustomAttribute) !== null && this.DeepFetcher(obj, Options.CustomAttribute) !== undefined && this.DeepFetcher(obj, Options.CustomAttribute).toString().toLowerCase().includes(searchWord)
          }
        } else {
          Found = Object.keys(obj).some(function (attr) {
            if (((Array.isArray(obj[attr])) || (typeof obj[attr] === 'object')) && (obj[attr] !== null)) {
              return _this.CookiesSearcher(obj[attr], searchWord)
            }
            if (obj[attr] !== null && obj[attr] !== undefined) {
              if (Options && Options.Exclusive) {
                return obj[attr].toString().toLowerCase() === searchWord
              } else {
                return obj[attr].toString().toLowerCase().includes(searchWord)
              }
            }
            return false
          })
        }
      })
      return Found
    },
    DeepFetcher (object :any, path :ObjectPath, Default?: any) {
      var value = object
      try {
        this.PathFixer(path).forEach(element => {
          value = value[element]
        })
        if (value !== undefined && value !== null && typeof value === 'number') {
          value = this.DecimalFixer(value)
        }
        if (value === undefined) {
          value = Default
        }
        return value
      } catch (error) {
        return Default
      }
    },
    JsonDateParser (JsonDate :any) {
      var DateToParse = new Date(JsonDate)
      return DateToParse.toLocaleDateString(['ban', 'id'], { year: 'numeric', month: 'numeric', day: 'numeric' })
    },
    JsonTimeParser (JsonDate :any) {
      var options :any = { timeStyle: 'short' }
      var DateToParse = new Date(JsonDate)
      return DateToParse.toLocaleTimeString('en-US', options)
    },
    AttributeArray (array :Array<any>, attribute: ObjectPath) {
      const _this = this
      var names = array.map(function (item :any) {
        return _this.DeepFetcher(item, attribute)
      })
      return names
    },
    ImageToObjURL (file :File) {
      if (!file) return ''
      return URL.createObjectURL(file)
    },
    DefaultImageHandler (ImgUrl ?:string, Default? :string) {
      if (Default === undefined) {
        Default = this.DefaultImage
      }
      return typeof ImgUrl === 'string' && ImgUrl !== '' ? ImgUrl === undefined ? Default : ImgUrl : Default
    },
    EmailValidator (email :string) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    PhoneValidator (Phone :string) {
      // const re = /(07)\w{8}$/
      const re = new RegExp('^[0-9+]{1,15}$') // ---- any number with a plus
      return re.test(Phone)
    },
    PasswordValidator (password :string, string = 'check') {
      // const passRegx = new RegExp('.*') // Anything on earth
      const passRegx = new RegExp('^[A-Za-z\\d@$!%*?&^#~]{5,25}$') // Any 5-25 characters
      const PassDescription = 'Password must be (5-25) Characters long'
      // const passRegx = new RegExp('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d@$!%*?&^#~]{5,25}$') // All CHaracters----- 5-25 characters ---- min: 1 letter ---- min: 1 number
      // const PassDescription = 'Password must be (5-25) Characters long & must contain at least one letter and one number'
      // const passRegx = new RegExp('^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*?&^#~])[A-Za-z\\d@$!%*?&^#~]{5,25}$') // All CHaracters----- 5-25 characters ---- min: 1 letter ---- min: 1 number
      // const PassDescription = 'Password must be (5-25) Characters long & must contain at least one letter and one number and one special character'
      if (string.toLowerCase() === 'check') {
        return passRegx.test(password)
      } else if (string.toLowerCase() === 'description') {
        return PassDescription
      }
    },
    OpenWhatsapp (number :string) {
      var Link = 'https://api.whatsapp.com/send?phone=962'
      window.open(Link + number.toString().substring(1), 'popUpWindow')
    },
    OnlyNumbersKeyPress () {
      const Myevent: any = event
      if (String.fromCharCode(Myevent.which) === '.' && (Myevent.target.innerText.indexOf('.') > -1)) {
        Myevent.preventDefault()
      // before TypeScript
      // } else if (isNaN(String.fromCharCode(Myevent.which)) && String.fromCharCode(Myevent.which) !== '.') {
      } else if (String.fromCharCode(Myevent.which) !== '.') {
        Myevent.preventDefault()
      }
    },
    /** element: DOM to be printed --- size:[width, height] in cm */
    PrintIT (element :HTMLElement | null, PrintingOptions ?:PrintingOptions) {
      if (element === null) {
        return
      }
      if (this.$store.state.PrintingOptions) {
        this.$store.state.PrintingOptions = {
          ...this.$store.state.PrintingOptions,
          ...PrintingOptions
        }
      }
      this.$store.state.Printing = element.cloneNode(true) as HTMLElement
    },
    DeepChanger (object :any, path :ObjectPath, newVal :any) {
      var x = object
      var FixedPath = this.PathFixer(path)
      for (var i = 0; i <= FixedPath.length - 1; i += 1) {
        if (i === FixedPath.length - 1) {
          x[FixedPath[i]] = newVal
        } else {
          if (x[FixedPath[i]] === undefined) {
            if (typeof FixedPath[i + 1] !== 'number') {
              x[FixedPath[i]] = {}
            } else {
              x[FixedPath[i]] = []
            }
          }
          x = x[FixedPath[i]]
        }
      }
    },
    CookiesPatcher (OldObj :any, NewObj :any) {
      var NormalValues = [] as Array<string>
      var ArrayValues = [] as Array<string>
      var ObjectValues = [] as Array<string>
      Object.keys(NewObj).forEach((value) => {
        if (Array.isArray(NewObj[value])) {
          if (JSON.stringify(OldObj[value]) !== JSON.stringify(NewObj[value])) {
            ArrayValues.push(value)
          }
          return
        }
        if (typeof NewObj[value] === 'object') {
          if (JSON.stringify(OldObj[value]) !== JSON.stringify(NewObj[value])) {
            ObjectValues.push(value)
          }
          return
        }
        NormalValues.push(value)
      })
      var OldNormalObj = {} as any
      var NewNormalObj = {} as any
      NormalValues.forEach((value) => {
        OldNormalObj[value] = OldObj[value]
        NewNormalObj[value] = NewObj[value]
      })
      var ComputedDiff = diff(OldNormalObj, NewNormalObj) as any
      ArrayValues.forEach((value) => {
        ComputedDiff[value] = NewObj[value]
      })
      ObjectValues.forEach((value) => {
        ComputedDiff[value] = NewObj[value]
      })
      return ComputedDiff
      // return this.Patcher.createPatch(OldObj, NewObj)
    },
    ObjectFinder (array :Array<any>, path :ObjectPath, value :any) {
      try {
        const found = array.find(element => this.DeepFetcher(element, path).toString() === value.toString())
        return found
      } catch (error) {
        return null
      }
    },
    IndexFinder (array :Array<any>, path :ObjectPath, value :any) {
      var found = array.findIndex(element => this.DeepFetcher(element, path) && (this.DeepFetcher(element, path).toString() === value?.toString()))
      return found
    },
    DeepCloner (Obj :any) {
      return JSON.parse(JSON.stringify(Obj))
    },
    PlaySound () {
      // console.log(SoundName)
    },
    Access (AccessFeature :AccessFeature) :boolean {
      // return true
      try {
        if (AccessFeature === 'CookiesAdmin' && !this.$store.state.UserManagment.CurrentUser.IsCookiesAdmin) return false
        if (AccessFeature !== 'CookiesAdmin' && AccessFeature !== 'SuperAdmin' && !this.HaveSystem(AccessFeature.split('_')[0] as SystemCode)) {
          return false
        }
        if (this.$store.state.UserManagment.CurrentUser.IsCookiesAdmin) return true
        if (AccessFeature.slice(-6) === 'Public' || this.$store.state.UserManagment.CurrentUser.AccessList.SuperAdmin) {
          return true
        }
        if (AccessFeature === undefined) {
          return false
        }
        return this.$store.state.UserManagment.CurrentUser.AccessList[AccessFeature] !== undefined && this.$store.state.UserManagment.CurrentUser.AccessList[AccessFeature]
      } catch (error) {
        return false
      }
    },
    HaveSystem (system :SystemCode) {
      // return true
      const Systems = this.$store.state.SystemManagment.CurrentSystems
      if (system === 'Main') return true
      var ConfigAvailable = this.$store.state.CookiesConfig[system + '_Active']
      if (ConfigAvailable === undefined || ConfigAvailable === 'false' || !ConfigAvailable) return false
      return Systems.some(element => {
        return element === system
      })
    },
    CookiesAuthorization () {
      // Default User Data ////////////////////////////////////////
      this.$store.state.UserManagment.CurrentUser = {
        Name: '',
        AccessList: {},
        Type: '',
        Email: ''
      }
      // //////////////////////////////////////////////////////////
      this.$store.state.UserManagment.Status = false
      // //////////////////////////////////////////////////////////
      var CurrenCompany = this.LocalRead('SelectedCompany')
      if (CurrenCompany) this.$store.state.CompanyManagment.CurrentCompany = CurrenCompany
      this.Get('Authorization').then((response :any) => {
        this.Console('Authorization')
        this.Console(response)
        this.$store.state.UserManagment.CurrentUser = response?.UserInfo
        this.$store.state.UserManagment.CurrentUser.RoleNames = response?.RoleNames
        this.$store.state.CompanyManagment.AvailableCompanies = response?.UserCompanies
        this.$store.state.CompanyManagment.CurrentCompany = response?.CurrentCompany
        this.$store.state.SystemManagment.CurrentSystems = response?.Systems
        if (this.$store.state.CompanyManagment.CurrentCompany) {
          var Branches = this.$store.state.CompanyManagment.CurrentCompany?.Branches
          this.$store.state.BranchManagment.AvailableBranches = Branches
          // //////////////////////////////////////////////////
          if (!Branches || Branches.length === 0) {
            // please Create New Branch
          } else if (Branches.length === 1) {
            this.$store.state.BranchManagment.CurrentBranch = Branches[0]
            if (this.Access('Inventory_Warehouses_Edit') && Branches[0].ID === 2) {
              this.MEMWrite('CurrentWarehouse', { Name: 'الرقيم', IDs: '[9,10,11,13,14,15,16,17,18,22,23,24,26,28,29,30,32,33,34,35,36,37,38,null]' })
            }
            this.LocalSave('SelectedBranch', Branches[0])
          } else {
            var LocalSelected = this.LocalRead('SelectedBranch')
            if (LocalSelected?.IsAll) {
              this.$store.state.BranchManagment.CurrentBranch = undefined
            } else if (LocalSelected) {
              var Found = Branches.find((branch :any) => {
                return branch.ID === LocalSelected.ID
              })
              if (Found) {
                this.$store.state.BranchManagment.CurrentBranch = Found
                if (this.Access('Inventory_Warehouses_Edit') && Found.ID === 2) {
                  this.MEMWrite('CurrentWarehouse', { Name: 'الرقيم', IDs: '[9,10,11,13,14,15,16,17,18,22,23,24,26,28,29,30,32,33,34,35,36,37,38,null]' })
                }
                this.LocalSave('SelectedBranch', Found)
              } else {
                this.OpenDialog('Inventory_Branch_Selector', this.Translate('Select Branch'), {}, { NoHeaderClose: true, NoBackgroundClose: true })
              }
            } else {
              console.log(this.$store.state.BranchManagment)
              this.OpenDialog('Inventory_Branch_Selector', this.Translate('Select Branch'), {}, { NoHeaderClose: true, NoBackgroundClose: true })
            }
          }
          const favicon = document.getElementById('FavIcon') as HTMLLinkElement
          this.$store.state.CookiesConfig.FavIco = this.$store.state.CompanyManagment.CurrentCompany.FavIconURL
          favicon.href = this.$store.state.CompanyManagment.CurrentCompany.FavIconURL
          // ///////////////////////////////////////////
        } else {
          if (this.$store.state.CompanyManagment.AvailableCompanies && this.$store.state.CompanyManagment.AvailableCompanies?.length > 0) {
            this.OpenDialog('Inventory_Company_Selector', this.Translate('Select Company'), {}, { NoHeaderClose: true, NoBackgroundClose: true })
          } else {
            this.OpenDialog('Company_Dialog', this.Translate('New Company'), {}, { NoBackgroundClose: true, NoHeaderClose: true })
          }
        }
        var AccessList :AccessList = {}
        try {
          response.RoleNames.forEach((element :string) => {
            AccessList[element.trim()] = true
          })
        } catch (error) {
        }
        this.$store.state.UserManagment.CurrentUser.AccessList = AccessList
        this.$store.state.UserManagment.Status = true
        if (this.Access(this.$route.meta.Access as AccessFeature)) {
          this.$store.state.Accessable = true
          this.Authorized()
        } else {
          this.$store.state.Accessable = false
          this.Console('NO ACCESS', 'font-size: 3em; color: red;')
          const Routes = router.getRoutes()
          const AccessableRoutes = Routes.reverse().filter(route => {
            return this.Access(route.meta.Access as AccessFeature)
          })
          if (AccessableRoutes[0]) this.Console(AccessableRoutes[0].path)
          if (AccessableRoutes[0]) this.$router.push(AccessableRoutes[0]).catch(error => { this.Console(error) })
          this.$store.state.Accessable = true
          // //////////////////////////////////////////////////////////////
          this.Authorized()
        }
      }, (error :Error) => {
        console.log(error)
        if (this.Access(this.$route.meta.Access as AccessFeature)) {
          this.$store.state.Accessable = true
          this.Authorized()
        } else {
          this.$store.state.AutoSignIn = false
          this.Authorized()
        }
      })
    },
    Authorized () {
      var Config = this.$store.state.CompanyManagment.CurrentCompany?.Config
      if (Config) this.ConfigMapper(Config)
      this.$store.state.Configed = true
      this.Console('Authorized')
    },
    RouteChange (to :any) {
      // is Public
      this.Console('RouteChange')
      this.Console(to)
      if (!this.$store) {
        return
      }
      if (this.Access(to.meta.Access)) {
        this.$store.state.Accessable = true
      } else {
        // Auto SIgn In
        if (!this.$store.state.UserManagment.Status) {
          this.CookiesAuthorization()
        } else {
          // this.$router.go(-1)
          this.NewAccessibleRoute()
        }
      }
    },
    NewAccessibleRoute () {
      this.$store.state.Accessable = false
      this.Console('NO ACCESS', 'font-size: 3em; color: red;')
      const Routes = router.getRoutes()
      const AccessableRoutes = Routes.reverse().filter(route => {
        return this.Access(route.meta.Access as AccessFeature)
      })
      this.Console(Routes)
      this.Console(AccessableRoutes)
      this.Console(AccessableRoutes[0].path)
      // this.$router.push(AccessableRoutes[0]).then(error => { this.Console(error) })
      window.location.href = AccessableRoutes[0].path
    },
    UserManagmentStatusCheck () {
      try {
        return this.$store.state.UserManagment.Status
      } catch (error) {
        return false
      }
    },
    CreditMapper (type :number, value = null) {
      if (value === null) {
        if (type === 1) {
          return 'Credit'
        } else {
          return 'Debit'
        }
      } else {
        return value
      }
    },
    Console (value :any, style = '') {
      if (!this.ConsoleLogs) {
        return
      }
      var ErrorStack = new Error()
      if (ErrorStack.stack !== undefined) {
        console.log('%c%s', 'font-size: 0.7em; color: #555555', ErrorStack.stack.split('\n')[2])
      }
      if (style === undefined) {
        style = ''
      }
      this.ConsoleLogs && console.log('%c%s', style, value)
      if (typeof value !== 'string' && value !== undefined) {
        console.log(value)
      }
      // this.$store.dispatch('Console', { value: value, style: style, target: EventTarget })
    },
    LocalSave (StorageName :string, value :any) {
      window.localStorage.setItem(StorageName, JSON.stringify(value))
    },
    LocalRead (StorageName :string) :any {
      var SavedItem = window.localStorage.getItem(StorageName)
      if (SavedItem === null) {
        return null
      }
      try {
        return JSON.parse(SavedItem)
      } catch (error) {
        return null
      }
    },
    SessionSave (StorageName :string, value :any) {
      window.sessionStorage.setItem(StorageName, JSON.stringify(value))
    },
    SessionRead (StorageName :string) :any {
      var SavedItem = window.sessionStorage.getItem(StorageName)
      if (SavedItem === null) {
        return null
      }
      try {
        return JSON.parse(SavedItem)
      } catch (error) {
        return null
      }
    },
    MEMRead (path :ObjectPath | undefined) :any {
      if (path === undefined) {
        return undefined
      }
      if (typeof path === 'string') {
        path = [path]
      }
      return this.DeepFetcher(this.$store.state.CookiesMemory, path)
    },
    MEMWrite (path :ObjectPath | undefined, newValue :any) {
      if (path === undefined) {
        return
      }
      if (typeof path === 'string') {
        path = [path]
      }
      return this.DeepChanger(this.$store.state.CookiesMemory, path, newValue)
    },
    TimeBeforeMounted () {
      var TimeZoneDiff = (new Date()).getTimezoneOffset() * 60000
      var DateFrom = new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0) - TimeZoneDiff).toISOString().replace(/T.*/, '').split('-').join('-') // grapping the beggining of today
      var DateTo = new Date(new Date().setHours(23, 59, 59, 999) - TimeZoneDiff).toISOString().replace(/T.*/, '').split('-').join('-') // grapping the end of today
      // var DateFrom = new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0) - TimeZoneDiff).toISOString().replace(/T.*/, '').split('-').join('-') // grapping the beggining of today
      // var DateTo = new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 999) - TimeZoneDiff).toISOString().replace(/T.*/, '').split('-').join('-') // grapping the beggining of today
      // var DateTo = new Date(new Date().setHours(23, 59, 59, 999) - TimeZoneDiff).toISOString().replace(/T.*/, '').split('-').join('-') // grapping the end of today
      this.$store.state.CurrentDate.From = DateFrom
      this.$store.state.CurrentDate.To = DateTo
      return {
        DateFrom: DateFrom,
        DateTo: DateTo
      }
    },
    DateChanged (DateFrom :any, DateTo :any) {
      var TimeZoneDiff = (new Date()).getTimezoneOffset() * 60000
      this.$store.state.CurrentDate.From = new Date(new Date(DateFrom).setHours(0, 0, 0, 0) - TimeZoneDiff).toISOString().replace(/T.*/, '').split('-').join('-') // grapping the beggining of today
      this.$store.state.CurrentDate.To = new Date(new Date(DateTo).setHours(23, 59, 59, 999) - TimeZoneDiff).toISOString().replace(/T.*/, '').split('-').join('-') // grapping the end of today
    },
    DateReader (date:any, dateTo?:any) {
      var TimeZoneDiff = (new Date()).getTimezoneOffset() * 60000
      var From = new Date(new Date(date).setHours(0, 0, 0, 0) - TimeZoneDiff).toISOString() // grapping the beggining of today
      var To = new Date(new Date(dateTo || date).setHours(23, 59, 59, 999) - TimeZoneDiff).toISOString() // grapping the end of today
      return { From: From, To: To }
    },
    CurrentDay () {
      var date = new Date()
      var TimeZoneDiff = (new Date()).getTimezoneOffset() * 60000
      var From = new Date(new Date(date).setHours(0, 0, 0, 0) - TimeZoneDiff).toISOString() // grapping the beggining of today
      var To = new Date(new Date(date).setHours(23, 59, 59, 999) - TimeZoneDiff).toISOString() // grapping the end of today
      return { From: From, To: To }
    },
    CurrentMonth () {
      var MonthEnd = new Date()
      var MonthStart = (new Date()).setDate(1)
      var TimeZoneDiff = (new Date()).getTimezoneOffset() * 60000
      var From = new Date(new Date(MonthStart).setHours(0, 0, 0, 0) - TimeZoneDiff).toISOString() // grapping the beggining of today
      var To = new Date(new Date(MonthEnd).setHours(23, 59, 59, 999) - TimeZoneDiff).toISOString() // grapping the end of today
      return { From: From, To: To }
    },
    LedgerFinder (AllLedgers :Array<LedgerInterface>, LedgerType :string, Type :number) {
      var Ledger: LedgerInterface = {
        Credited: 0,
        Debited: 0
      }
      try {
        if (AllLedgers === null) { return }
        if (this.ObjectFinder(AllLedgers, ['Type'], LedgerType) !== undefined) {
          Ledger = this.ObjectFinder(AllLedgers, ['Type'], LedgerType)
          if (Type === 1) {
            Ledger.NetBalance = Ledger.Debited - Ledger.Credited
          } else {
            Ledger.NetBalance = Ledger.Credited - Ledger.Debited
          }
        }
      } catch (error) {
      }
      return Ledger
    },
    TotalOf (CookiesData :Array<any>, Path :ObjectPath) {
      try {
        if (typeof Path === 'string') {
          Path = [Path]
        }
        var Total = 0
        CookiesData.forEach(element => {
          Total += parseFloat(this.DeepFetcher(element, Path))
        })
        // const Total = CookiesData.reduce(function (Sum, element) {
        //   return Sum + parseFloat(this.DeepFetcher(element, Path))
        // })
        return Total
      } catch (error) {
      }
    },
    FullScreen () {
      interface ModifiedDocument extends Document {
        mozFullScreenElement?: Element,
        msFullscreenElement?: Element,
        webkitFullscreenElement?: Element,
        msExitFullscreen?: () => void,
        mozCancelFullScreen?: () => void,
        webkitExitFullscreen?: () => void,
      }
      var MyDocument: ModifiedDocument = document
      var MainApp: any = MyDocument.getElementById('CookiesApp')
      if (MyDocument.fullscreenElement === null) {
        if (MainApp.requestFullscreen) {
          MainApp.requestFullscreen()
        } else if (MainApp.webkitRequestFullscreen) { /* Safari */
          MainApp.webkitRequestFullscreen()
        } else if (MainApp.msRequestFullscreen) { /* IE11 */
          MainApp.msRequestFullscreen()
        }
      } else {
        MyDocument.exitFullscreen()
        try {
          if (MyDocument.exitFullscreen) {
            MyDocument.exitFullscreen()
          } else if (MyDocument.webkitExitFullscreen) { /* Safari */
            MyDocument.webkitExitFullscreen()
          } else if (MyDocument.msExitFullscreen) { /* IE11 */
            MyDocument.msExitFullscreen()
          }
        } catch (error) {
        }
      }
    },
    DayNumber (theDate :any) {
      return (new Date(theDate)).getTime() / (1000 * 60 * 60 * 24)
    },
    MinMaxCalculator (Units :Array<any>, Path :ObjectPath) {
      var min = this.DeepFetcher(Units[0], Path)
      var max = this.DeepFetcher(Units[0], Path)
      Units.forEach(unit => {
        var value = this.DeepFetcher(unit, Path)
        if (this.DeepFetcher(unit, Path) < min) min = value
        if (this.DeepFetcher(unit, Path) > max) max = value
      })
      if (max === min) {
        return min
      } else {
        return max + ' - ' + min
      }
    },
    /** fixes old functions with one path elements (string) and return that string in a singular array --- ie: 'path' > ['path'] */
    PathFixer (attribute :string | Array<string | number> | number) :Array<string | number> {
      if (typeof attribute === 'string' || typeof attribute === 'number') {
        return [attribute]
      } else {
        return attribute
      }
    },
    Requirments (_this :any) {
      try {
        if (_this.$el.nextSibling.querySelector('[CookiesInvalidInput = true]') === null) {
          return { Incomplete: false }
        } else {
          return { Incomplete: true }
        }
      } catch (error) {
        return { Incomplete: true }
      }
    },
    GeneralSort (MyData: Array<any>, Path: ObjectPath, Direction :SortDirection) {
      const _this = this
      function BeginSorting (a :any, b :any) {
        try {
          var SortElement = Path
          var SortDirection = Direction
          if (SortDirection === undefined) { return 0 }
          var aValue = _this.DeepFetcher(a, SortElement)
          var bValue = _this.DeepFetcher(b, SortElement)
          if (typeof aValue !== typeof bValue) {
            aValue = aValue.toString()
            bValue = bValue.toString()
          }
          try {
            if (aValue > bValue) {
              return SortDirection
            }
            if (aValue < bValue) {
              return SortDirection * -1
            }
            return 0
          } catch (error) {
            return 0
          }
        } catch (error) {
          return 0
        }
      }
      var x = MyData.sort(BeginSorting)
      return x
    },
    SortTable (name :ObjectPath, element :string) {
      if (element !== undefined && element !== null) {
        var _this :any = this
        var thisElement :SortElement = _this[element]
        this.Console(thisElement)
        if (JSON.stringify(thisElement.Path) === JSON.stringify(name)) {
          thisElement.Direction === 1 ? thisElement.Direction = -1 : thisElement.Direction = 1
        } else {
          thisElement.Path = name
        }
      }
    },
    ChangePassword (email: string) {
      this.OpenDialog('Security_ChangePassword_Dialog', this.Translate('Change Password'), { Email: email }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CompareDates (Date1: Date, Date2: Date, Hours = false) {
      var DATE1Decoded
      var DATE2Decoded
      if (!Hours) {
        DATE1Decoded = new Date(new Date(Date1).setHours(0, 0, 0, 0)).getTime()
        DATE2Decoded = new Date(new Date(Date2).setHours(0, 0, 0, 0)).getTime()
      } else {
        DATE1Decoded = (new Date(Date1)).getTime()
        DATE2Decoded = (new Date(Date2)).getTime()
      }
      if (DATE1Decoded > DATE2Decoded) {
        return 1
      }
      if (DATE1Decoded < DATE2Decoded) {
        return 2
      }
      if (DATE1Decoded === DATE2Decoded) {
        return 0
      }
    },
    LogOut () {
      this.LocalSave('CookiesJWT', null)
      this.LocalSave('CookiesBAKERY', null)
      this.SessionSave('CookiesJWT', null)
      this.SessionSave('CookiesBAKERY', null)
      location.reload()
    },
    ArrayHandler (data :Array<string> | string) {
      if (Array.isArray(data)) {
        return data
      } else {
        return []
      }
    },
    CookiesFilter (Data :Array<any>, Filter :FilterObject | undefined, Search = '') :Array<any> {
      return Data.filter((item: any) => {
        var FilterIt = false
        var SearchIt = false
        // ================================================================================================
        // -------------------------------------------- filtering by switches
        // ---------------------------------------- -------------------------
        // this will filter the items that deosnt have a value equals to a CHECKED switch
        // ((All filtered items will be hidden and will not be processed in next filters))
        // ---------------------------------------- -------------------------
        if (Filter) {
          if (!FilterIt && Filter.Switches && Filter.Switches?.length && Filter.Switches?.length > 0) {
            FilterIt = Filter.Switches.some(Switch => {
              return Switch.Visible && Switch.DataPath !== undefined && Switch.Value !== undefined && this.MEMRead(Switch.Res) && (this.DeepFetcher(item, Switch.DataPath) !== Switch.Value)
            })
          }
          // ================================================================================================
          // ---------------------------------------------- filtering by checks
          // ---------------------------------------- -------------------------
          // this will filter the items that have a value equals to an UNCHECKED checkbox
          // ((All filtered items will be hidden and will not be processed in next filters))
          // ---------------------------------------- -------------------------
          if (!FilterIt && Filter.Checks && Filter.Checks?.length && Filter.Checks?.length > 0) {
            // FilterIt = Filter.Checks.some(check => {
            //   return check.Visible && check.DataPath !== undefined && check.Value !== undefined && !this.MEMRead(check.Res) && (this.DeepFetcher(item, check.DataPath) === check.Value)
            // })
            Filter.Checks.forEach((filterGroup :FilterGroup) => {
              if (!FilterIt) {
                FilterIt = filterGroup.Checks.some(check => {
                  return check.Visible && check.DataPath !== undefined && check.Value !== undefined && !this.MEMRead(check.Res) && (this.DeepFetcher(item, check.DataPath) === check.Value)
                })
              }
            })
          }
          // ================================================================================================
          // ---------------------------------------- filtering by lists
          // ---------------------------------------- -------------------------
          // this will filter the items that deosnt apply to the selected elements in the list
          // ((All filtered items will be hidden and will not be processed in next filters))
          // ---------------------------------------- -------------------------
          if (!FilterIt && Filter.Lists && Filter.Lists?.length && Filter.Lists?.length > 0) {
            FilterIt = Filter.Lists.some(list => {
              if (!(list.Visible && list.ListPath !== undefined && list.DataPath !== undefined && this.MEMRead(list.Res + '_Value') !== undefined)) {
                return false
              } else {
                var SelectedItem = this.DeepFetcher(this.MEMRead(list.Res + '_Value'), list.ListPath)
                return this.DeepFetcher(item, list.DataPath) !== SelectedItem
              }
            })
          }
        }
        // ================================================================================================
        // ---------------------------------------- filtering by search
        if (!FilterIt) {
          SearchIt = this.CookiesSearcher(item, Search)
        }
        return !FilterIt && SearchIt
      })
    },
    /** DateStyle variables: ( Time, DD, MM, YYYY, Year, Day, Month, FullDay, FullMonth )
     *
     * Example: DateFormatter('2021-04-02T15:06:58.3817837', 'DD Month YYYY - Time') ==> 2 April 2022 - 3:06 PM
     *
     * **Default DateStyle Value: 'DD/MM/YYYY'**
    */
    DateFormatter (DateValue: string | number | Date, DateStyle = 'DD/MM/YYYY') {
      this.Console(DateStyle)
      if (DateValue === undefined || DateValue === null) return ''
      // basic naming -------------------------------------------
      var Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      var Days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      // get my date -------------------------------------------
      var DateToParse = new Date(DateValue)
      // encoder -------------------------------------------
      var FullDay = Days[DateToParse.getDay()]
      var Day = FullDay?.slice(0, 3)
      var FullMonth = Months[DateToParse.getMonth()]
      var Month = FullMonth?.slice(0, 3)
      var DD = DateToParse.getDate().toString()
      var MM = (DateToParse.getMonth() + 1).toString()
      var YYYY = DateToParse.getFullYear().toString()
      var hours = (DateToParse.getHours() % 12) || 12
      var minutes = DateToParse.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
      var AMPM = DateToParse.getHours() >= 12 ? this.Translate('PM') : this.Translate('AM')
      var Time = hours + ':' + minutes + ' ' + AMPM
      // replacer -------------------------------------------
      var MyTime = DateStyle.replaceAll(/DD/ig, DD)
      MyTime = MyTime.replaceAll(/MM/ig, MM)
      MyTime = MyTime.replaceAll(/YYYY/ig, YYYY)
      MyTime = MyTime.replaceAll(/YYYY|Year/ig, YYYY)
      MyTime = MyTime.replaceAll(/FullDay/ig, '#CookiesReplacer#')
      MyTime = MyTime.replaceAll(/FullMonth/ig, '#CookiesReplacer2#')
      MyTime = MyTime.replaceAll(/Day/ig, Day)
      MyTime = MyTime.replaceAll(/Month/ig, Month)
      MyTime = MyTime.replaceAll(/#CookiesReplacer#/ig, FullDay)
      MyTime = MyTime.replaceAll(/#CookiesReplacer2#/ig, FullMonth)
      MyTime = MyTime.replaceAll(/Time/ig, Time)
      // -------------------------------------------
      return MyTime
    },
    CookiesDialogInitiator (DialogMapper: Array<DialogMapperObject>, Data: any) {
      var OriginalData = {}
      DialogMapper.forEach(mapper => {
        var elementValue = this.DeepFetcher(Data, mapper.Path)
        if (mapper.Array) {
          try {
            var ModifiedArray :Array<any> = this.DeepFetcher(Data, mapper.Path).map((element :any) => {
              var ComputedElement :any = {}
              if (mapper.Array && Array.isArray(mapper.Array) && Array.isArray(mapper.Array[0])) {
                mapper.Array.forEach(ArrayElem => {
                  this.DeepChanger(ComputedElement, ArrayElem, this.DeepFetcher(element, ArrayElem))
                })
              } else if (mapper.Array && typeof mapper.Array === 'string') {
                ComputedElement = this.DeepFetcher(element, mapper.Array)
              } else if (mapper.Array) {
                mapper.Array.forEach(ArrayElem => {
                  this.DeepChanger(ComputedElement, ArrayElem, this.DeepFetcher(element, ArrayElem))
                })
              }
              return ComputedElement
            })
            this.DeepChanger(OriginalData, mapper.Path, ModifiedArray)
            this.MEMWrite(mapper.Res, elementValue)
          } catch (error) {
          }
        } else {
          if (elementValue) {
            mapper.Default = elementValue
          }
          this.DeepChanger(OriginalData, mapper.Path, elementValue)
          this.MEMWrite(mapper.Res, elementValue)
        }
      })
      return OriginalData
    },
    DialogDataDecoder (DialogMapper: Array<DialogMapperObject>, Saving = true) :any {
      var Data = {}
      DialogMapper.forEach(mapper => {
        if (Saving && mapper.notForSave) {
          return
        }
        var SavingPath = mapper.Path
        if (mapper.SavingPath) {
          SavingPath = mapper.SavingPath
        }
        if (mapper.Array) {
          if (!this.MEMRead(mapper.Res)) {
            this.DeepChanger(Data, SavingPath, [])
          } else {
            var ModifiedArray :Array<any> = this.MEMRead(mapper.Res).map((element :any) => {
              var ComputedElement :any = {}
              if (mapper.Array && (typeof mapper.Array === 'string')) {
                ComputedElement = this.DeepFetcher(element, mapper.Array)
              } else if (mapper.Array) {
                mapper.Array.forEach(ArrayElem => {
                  this.DeepChanger(ComputedElement, ArrayElem, this.DeepFetcher(element, ArrayElem))
                })
              }
              return ComputedElement
            })
            this.DeepChanger(Data, SavingPath, ModifiedArray)
          }
        } else if (mapper.Res === undefined && mapper.Value !== undefined) {
          this.DeepChanger(Data, SavingPath, mapper.Value)
        } else if (mapper.Res && mapper.ValuePath !== undefined && this.DeepFetcher(this.MEMRead(mapper.Res + '_Value'), mapper.ValuePath)) {
          this.DeepChanger(Data, SavingPath, this.DeepFetcher(this.MEMRead(mapper.Res + '_Value'), mapper.ValuePath))
        } else if (mapper.Res && this.MEMRead(mapper.Res) === undefined && mapper.Default !== undefined) {
          this.DeepChanger(Data, SavingPath, mapper.Default)
        } else {
          this.DeepChanger(Data, SavingPath, this.MEMRead(mapper.Res))
        }
      })
      return Data
    },
    SaveDialogData (
      Options: {
        DialogMapper: Array<DialogMapperObject>,
        Parentthis: any,
        PostAPI?:API_NAME,
        OriginalData?: any,
        PatchAPI?:API_NAME,
        Editing?: boolean,
        PatchQuery?: string
        PostQuery?: string,
        MultiSave?: boolean
      }
    ) {
      const _this = Options.Parentthis
      if (_this.Saving && !Options.MultiSave) return
      // --------------------------------------------------------------
      // ----------------------- Validity Check -----------------------
      // --------------------------------------------------------------
      // checking any dom inside this dialog that contains attribute CookiesInvalidInput = true
      _this.Saving = true
      if (_this.Requirments(_this).Incomplete) {
        console.log('Incomplete Data')
        _this.ErrorsVisibilty = true
        _this.Saving = false
        _this.GlobalSaveDone(false)
      } else {
        // --------------------------------------------------------------
        // ------------------ Array Delete Handler -----------------------
        // --------------------------------------------------------------
        this.Console('DialogArrayDeleteHandler:')
        this.DialogArrayDeleteHandler(Options).then(response => {
          this.Console(response)
          Options.OriginalData = response
          // --------------------------------------------------------------
          // ----------------------- Images Handling -----------------------
          // --------------------------------------------------------------
          this.Console('DialogImagesUploader:')
          this.DialogImagesUploader(Options.DialogMapper).then(() => {
            this.Console('PostNow()')
            this.Console(Options)
            PostNow()
          }, error => {
            console.log(error)
            _this.Saving = false
            _this.GlobalSaveDone(false)
            _this.Console(error)
            _this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
          })
        }, error => {
          _this.Saving = false
          _this.GlobalSaveDone(false)
          _this.Console(error)
          _this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
      // Post // Patch
      var _this2 = this
      function PostNow () {
        Options.DialogMapper.forEach(mapper => {
          if (mapper.SavingPath) {
            _this.DeepChanger(Options.OriginalData, mapper.SavingPath, _this.DeepFetcher(Options.OriginalData, mapper.Path))
            _this.DeepChanger(Options.OriginalData, mapper.Path, undefined)
          }
        })
        var CurrentDialogData = _this.DialogDataDecoder(Options.DialogMapper)
        let Method :POSTMethods = 'POST'
        let API = Options.PostAPI
        let AddQuery = Options.PostQuery
        let Data = CurrentDialogData
        var SavingError = 'Error in Saving'
        if (Options.Editing) {
          Method = 'PATCH'
          API = Options.PatchAPI
          AddQuery = Options.PatchQuery
          _this.Console('PAAAAAAAAAATCH', 'font-size: 2em;')
          _this.Console(Options.OriginalData)
          _this.Console(CurrentDialogData)
          console.log('PAAAAAAAAAATCH')
          console.log(Options.OriginalData)
          console.log(CurrentDialogData)
          Data = _this.CookiesPatcher(Options.OriginalData, CurrentDialogData)
          if (Object.keys(Data).length === 0) {
            _this.DataSaved()
            return
          }
          _this.Console(Data)
        }
        if (API === undefined) {
          return
        }
        _this2.Post(Method, API, Data, AddQuery).then(response => {
          _this.DataSaved(response)
        }, error => {
          _this.Saving = false
          _this.GlobalSaveDone(false)
          _this.Console(error)
          error.CookiesError = SavingError
          _this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
    },
    DialogImagesUploader (DialogMapper: Array<DialogMapperObject>) {
      return new Promise((resolve :any, reject) => {
        // --------------------------------------------------------------
        // ----------------------- Images Handling -----------------------
        // --------------------------------------------------------------
        var DialogElementsCount = DialogMapper.length
        var ElementsChecked = 0
        DialogMapper.forEach(mapper => {
          if (mapper.IsImage && this.MEMRead(mapper.Res) && ((typeof this.MEMRead(mapper.Res)) !== 'string')) {
            this.PostImage(this.MEMRead(mapper.Res)).then(response => {
              this.MEMWrite(mapper.Res, this.DeepFetcher(response, 'url'))
              ElementsChecked += 1
              ElementsChecker()
            }, error => {
              error.CookiesError = 'Error in Uploading Image'
              reject(error)
            })
          } else {
            ElementsChecked += 1
            ElementsChecker()
          }
        })
        // ElementsChecker
        function ElementsChecker () {
          if (ElementsChecked === DialogElementsCount) {
            resolve()
          }
        }
      })
    },
    DialogArrayDeleteHandler (
      Options: {
        DialogMapper: Array<DialogMapperObject>,
        Parentthis: any,
        PostAPI?:API_NAME,
        OriginalData?: any,
        PatchAPI?:API_NAME,
        Editing?: boolean,
        PatchQuery?: string
        PostQuery?: string
      }
    ) {
      const _this = this
      return new Promise((resolve :any, reject) => {
        resolve(Options.OriginalData)
        // ElementsChecker
        // function ElementsChecker (UnEditedRemovedOriginalData: any) {
        //   if (ElementsChecked === DialogElementsCount) {
        //     var CurrentDialogData = UnEditedRemovedOriginalData
        //     var Method :POSTMethods = 'PATCH'
        //     var API = Options.PatchAPI
        //     var AddQuery = Options.PatchQuery
        //     _this.Console('PPPPPaaAAAATCH')
        //     _this.Console(Options.OriginalData)
        //     _this.Console(CurrentDialogData)
        //     console.log('PPPPPaaAAAATCH')
        //     console.log(Options.OriginalData)
        //     console.log(CurrentDialogData)
        //     var Data = _this.CookiesPatcher(Options.OriginalData, CurrentDialogData)
        //     var SavingError = 'Error in Saving'
        //     // if (Data.length === 0) {
        //     //   resolve(UnEditedRemovedOriginalData)
        //     //   return
        //     // }
        //     if (API === undefined) {
        //       return
        //     }
        //     _this.Post(Method, API, Data, AddQuery).then(() => {
        //       resolve(UnEditedRemovedOriginalData)
        //     }, (error :any) => {
        //       reject(error)
        //       error.CookiesError = SavingError
        //       _this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        //     })
        //   }
        // }
      })
    },
    ChangeTableButtons (_this :any, Buttons :any) {
      _this.Buttons = Buttons
    },
    ChangeFilter (_this :any, Filter ?:FilterObject) {
      _this.Filter = Filter
    },
    RowHighliter (Res :string, Row :RowHighliterObject) {
      var DefualtOverwite :RowHighliterObject = {
        HighlightColor: this.Theme.UserColors.main,
        ...Row
      }
      var ResObject :Array<RowHighliterObject> = this.MEMRead(Res)
      if (ResObject === undefined) {
        var EmptyArray :Array<RowHighliterObject> = []
        this.MEMWrite(Res, EmptyArray.push(DefualtOverwite))
      } else {
        this.MEMWrite(Res, ResObject.push(DefualtOverwite))
      }
      setTimeout(() => {
        this.MEMWrite(Res, this.MEMRead(Res).shift())
      }, 1200)
    },
    /** this functions takes the passed resource and clean all of the resources starts with this specific resource from the memory, You can pass specefic = true and it will only remove the exact resource alone */
    MEMClean (Res :string, Specific = false) {
      var Memory = this.$store.state.CookiesMemory
      if (Specific) {
        this.MEMWrite(Res, undefined)
        return
      }
      Object.keys(Memory).some(function (attr) {
        if (attr.startsWith(Res)) {
          delete Memory[attr]
        }
      })
    },
    PushNotification (Message :string) {
      this.$store.state.CookiesNotifications.Messages.push(Message)
    },
    WhatsappQR (QR :any) {
      this.Console('WhatsappQR')
      this.Console(QR)
    },
    WhatsAppInit () {
      this.Console('WhatsappInit')
    },
    async WhatsappSendMessage (message :any, HeadsUpMessage = true) {
      return new Promise((resolve :any, reject) => {
        const OriginalPhoneNumber = this.DeepCloner(message.to)
        message.to = this.PhoneHandler(message.to)
        const RequestOptions = {
          method: 'POST',
          body: JSON.stringify(message),
          headers: {
            'Content-Type': 'application/json',
            'Accept-Encoding': 'gzip, deflate, br',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + this.$store.state.CookiesConfig.WhatsappToken
          }
        }
        fetch('https://graph.facebook.com/v14.0/' + this.$store.state.CookiesConfig.WhatsappPhoneNumberID + '/messages', RequestOptions)
          .then(CookiesResponse => {
            if (CookiesResponse.ok) {
              var NotificationTitle = 'All Catalogs'
              if (message?.template?.name === 'dynamic_catalog') NotificationTitle = message?.template?.components[1]?.parameters[0]?.text
              this.Post('POST', 'Notification_History', { Recipient: OriginalPhoneNumber, Type: 'Whatsapp', Title: NotificationTitle }).then(response => {
                if (HeadsUpMessage) {
                  this.PushNotification(this.Translate('Message Sent Succesfully'))
                }
                resolve('Done')
              }, error => {
                this.PushNotification(this.Translate('Message sent successfully! Error in saving notification'))
                console.log(error)
                resolve('Done')
              })
            } else {
              this.PushNotification(this.Translate('Error in sending message'))
              reject(CookiesResponse)
            }
          })
          .catch(error => {
            console.log(error)
            this.Console(error)
            reject(error)
          })
      })
    },
    PasteFixer (event :any, Multiline = true) {
      event.preventDefault()
      var CopiedData = event.clipboardData.getData('text/plain')
      if (!Multiline) {
        CopiedData = CopiedData.replaceAll('<br>', '')
        CopiedData = CopiedData.trim()
        CopiedData = CopiedData.replace(/[\r\n]/gm, '')
      }
      document.execCommand('inserttext', true, CopiedData)
    },
    ExportFromJSON (data :any, fileName :string, exportType :any = csvexportType) {
      exportFromJSON({ data, fileName, exportType })
    },
    DateAPIURL () {
      // var TimeZoneDiff = (new Date()).getTimezoneOffset() * 60000
      var From = this.$store.state.CurrentDate.From
      var To = this.$store.state.CurrentDate.To
      console.log(this.CurrentDate)
      return 'DateFrom=' + From + '&DateTo=' + new Date(new Date(To).setDate(new Date(To).getDate() + 1)).toISOString().replace(/T.*/, '').split('-').join('-')
    },
    SaveConfig (attr :string, Value :any, ParentThis ?:any) {
      var newItem = {
        Name: attr,
        Value: typeof Value === 'string' ? Value : Value.toString()
      }
      this.Post('POST', 'Config', newItem, '?UpdateOn=Name').then(() => {
        if (ParentThis) ParentThis.Saving = false
        this.ReadConfig()
      }, error => {
        if (ParentThis) ParentThis.Saving = false
        error.CookiesError = 'Error in Saving Config'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditConfig (attr :string, Value :any, ParentThis ?:any) {
      if (ParentThis) ParentThis.Saving = true
      var OriginalConfig = this.LocalRead('CookiesDefaultConfig')
      var GetItem = OriginalConfig[attr]
      if (GetItem === null || GetItem === undefined) {
        this.SaveConfig(attr, Value)
        return
      }
      var ClonedItem = this.DeepCloner(GetItem)
      var OriginalItem = {
        Value: ClonedItem
      }
      var NewItem = {
        Value: Value
      }
      var Patch = this.CookiesPatcher(OriginalItem, NewItem)
      // if (Patch.length === 0) {
      //   if (ParentThis) ParentThis.Saving = false
      //   return
      // }
      this.Post('PATCH', 'Config', Patch, '/' + OriginalConfig[attr + 'ID']).then(() => {
        if (ParentThis) ParentThis.Saving = false
        this.ReadConfig()
      }, error => {
        if (ParentThis) ParentThis.Saving = false
        error.CookiesError = 'Error in Editing Config'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    RefreshPage () {
      window.location.reload()
    },
    SwapArrayElements (array :Array<any>, index1 :number, index2 :number) {
      const temp :any = array[index1]
      // Step 2
      array[index1] = array[index2]
      // Step 3
      array[index2] = temp
    },
    ColorsGenerator (n = 9) {
      // return chroma.scale(['#fafa6e', '#2A4858']).mode('lch').colors(n)
      return chroma.scale(['#fafa6e', '#2A4858']).mode('lch').colors(n)
    },
    PhoneHandler (phone :any) {
      var reGlobal1 = /^[+]([1-9])([0-9]){7,15}$/
      var reGlobal2 = /^[0][0]([1-9])([0-9]){7,15}$/
      var re3Jordan = /^[0][7][9,8,7]([0-9]){7}$/
      var FixedPhone = phone.replaceAll(/\s/g, '')
      if (reGlobal1.test(FixedPhone)) {
        FixedPhone = FixedPhone.replace('+', '')
      } else if (reGlobal2.test(FixedPhone)) {
        FixedPhone = FixedPhone.replace('00', '')
      } else if (re3Jordan.test(FixedPhone)) {
        FixedPhone = FixedPhone.replace('0', '962')
      }
      return FixedPhone
    },
    GlobalSave () {
      this.$store.state.CookiesMemory.GlobalSaving.push('Saving')
    },
    GlobalSaveDone (ok = true) {
      this.$store.state.CookiesMemory.GlobalSaving.pop()
      if (ok) {
        this.$store.state.CookiesMemory.GlobalSavingDone.push('Done')
        setTimeout(() => {
          this.$store.state.CookiesMemory.GlobalSavingDone.pop()
        }, 1000)
      } else {
        this.$store.state.CookiesMemory.GlobalSavingFailed.push('Done')
        setTimeout(() => {
          this.$store.state.CookiesMemory.GlobalSavingFailed.pop()
        }, 1000)
      }
    },
    async ImagesToZip (Images: Array<{Name: string, UrlOrURI: string}>, FileName = 'Images') {
      const zip = new JSZip()
      const promises = Images.map(async (image) => {
        const response = await fetch(image.UrlOrURI)
        const blob = await response.blob()
        const blobExtension = blob.type?.split('/')[1]
        zip.file(`${image.Name}.${blobExtension}`, blob) // You can change the file extension as needed
      })
      // Wait for all image files to be added to the zip
      await Promise.all(promises)
      // Generate and download zip file
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const url = window.URL.createObjectURL(content)
        const a = document.createElement('a')
        a.href = url
        a.download = FileName + 'images.zip'
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
    },
    GetDataURLExtension (dataURI :any) {
      try {
        // Split the data URI into parts: "data:image/ext;base64,data..."
        const parts = dataURI.split(';')
        // Extract the MIME type part: "data:image/ext;base64"
        const mimeTypePart = parts[0]
        // Split the MIME type part to get the extension: "image/ext"
        const mimeType = mimeTypePart.split(':')[1]
        // Extract the extension by splitting at the "/"
        const extension = mimeType.split('/')[1]
        return extension
      } catch (error) {
        return ''
      }
    },
    ViewImage (url: string) {
      this.OpenDialog('SingleImageViewer', '', { ImgURL: url }, { header: false, background: 'none' })
    },
    CalendarFormatter (Calendar :Calendar) {
      // 'Yearly (4 Jan,4 Oct)'
      // 'Weekly (Sun,Mon)'
      if (!Calendar) return ''
      var CalendarFormat = ''
      if (Calendar.Type === 'Yearly') {
        CalendarFormat = 'Yearly' + ' ' + Calendar.DaysOfMonth + ' ' + ' From ' + '' + `(${Calendar.MonthsOfYear})`
      } else if (Calendar.Type === 'Monthly') {
        CalendarFormat = 'Monthly' + '' + `(${Calendar.DaysOfMonth})`
      } else if (Calendar.Type === 'Weekly') {
        CalendarFormat = 'Weekly' + '' + `(${Calendar.DaysOfWeek})`
      } else if (Calendar.Type === 'Daily') {
        CalendarFormat = 'Daily'
      } else if (Calendar.Type === 'Hourly') {
        CalendarFormat = 'Hourly'
      }
      return CalendarFormat
    },
    TimeFormatter (Time :string) {
      // 12:49:00.000000
      // 12:49
      if (!Time) return ''
      return Time.replace(':00.000000', '')
    }
  },
  watch: {
    RouteQuery: {
      handler: function (to) {
        if (this.Access(this.$route.meta.Access as AccessFeature)) {
          this.$store.state.Accessable = true
        }
        const CurrentDialogs = this.$store.state.CookiesDialog.OpenedDialogs
        CurrentDialogs.forEach(dialog => {
          this.Console(to['Dialog' + dialog.Num])
          if (to['Dialog' + dialog.Num] === undefined) {
            this.CloseDialog(dialog.Num)
          }
        })
      }
    }
  }
})
