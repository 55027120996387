
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      Departments: [] as Array<Department>,
      CookiesData: [] as Array<Employee>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {
        Lists: [
          {
            Name: 'Department',
            Visible: true,
            Options: this.Departments,
            Selectors: [['Name']],
            Res: 'HR_Filter_Department',
            DataPath: ['Department', 'ID'],
            ListPath: ['ID']
          }
        ],
        Checks: [
          {
            GroupName: 'Status',
            Checks: [
              {
                Name: this.Translate('Active'),
                Visible: this.Access('HR_DailyEmployees_Edit'),
                Icon: 'Done',
                Res: 'Users_Filter_Active',
                Value: 'Active',
                DataPath: ['Status']
              },
              {
                Name: this.Translate('Inactive'),
                Visible: this.Access('HR_DailyEmployees_Edit'),
                Icon: 'Inactive',
                Res: 'Users_Filter_Inactive',
                Value: 'Inactive',
                DataPath: ['Status']
              }
            ]
          }
        ]
      }
    },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Image',
            Path: 'ImgUrl',
            Sort: ['ImgUrl'],
            Style: 'width: 2em',
            ImageStyle: 'border-radius: 100%; width: 3em; height: 3em;',
            ImageClass: 'Shadow1',
            ImageDefault: this.$store.state.CookiesConfig.UserImage
          },
          {
            Type: 'Normal',
            Name: 'ID',
            Path: ['ID'],
            Sort: ['ID'],
            Bold: true,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Phone',
            Path: ['PhoneNumber'],
            Sort: ['PhoneNumber'],
            IsSearchable: true,
            IsSearchExclusive: true
          },
          {
            Type: 'Normal',
            Name: 'Email',
            Path: ['Email'],
            Sort: ['Email'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Department',
            Path: ['Department', 'Name'],
            Sort: ['Department', 'Name'],
            IsSearchable: true,
            Secondary: {
              Path: ['Position', 'Name']
            }
          },
          {
            Type: 'DateTime',
            Name: 'Hire Date',
            Path: 'HireDate',
            Sort: 'HireDate',
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Salary',
            Path: ['Salary'],
            Sort: ['Salary'],
            Unit: this.PriceUnit,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Nationality',
            Path: ['Nationality', this.$store.state.CookiesConfig.Language],
            Sort: ['Nationality', this.$store.state.CookiesConfig.Language],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Gender',
            Path: ['Gender'],
            Sort: ['Gender'],
            IsSearchable: true
          },
          {
            Type: 'DateTime',
            Name: 'Birth Date',
            Path: 'BirthDate',
            Sort: 'BirthDate',
            IsSearchable: true
          },
          {
            Type: 'QR',
            Name: 'QRCode',
            Path: 'Name',
            Sort: 'Name'
          },
          {
            Type: 'StatusBox',
            Name: 'Status',
            Path: 'Status',
            Sort: 'Status',
            StatusClassColors: {
              Active: 'BGGreen',
              Inactive: 'BGRed'
            },
            Bold: true
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Inactive',
                Visible: this.Access('HR_DailyEmployees_Edit'),
                ToolTip: this.Translate('Activate'),
                Style: 'filter: saturate(0); opacity: 0.5;',
                Emits: 'SwapStatus',
                DynamicVisiblity: {
                  FixedValue: 'Inactive',
                  Operator: 'EQUAL LOWERCASE',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Inactive',
                Visible: this.Access('HR_DailyEmployees_Edit'),
                ToolTip: this.Translate('Deactivate'),
                Emits: 'SwapStatus',
                DynamicVisiblity: {
                  FixedValue: 'Active',
                  Operator: 'EQUAL LOWERCASE',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Edit',
                Visible: this.Access('HR_DailyEmployees_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditDailyEmployee'
              },
              {
                ID: 'Delete',
                Visible: this.Access('HR_DailyEmployees_Remove'),
                ToolTip: this.Translate('Remove'),
                Emits: 'DeleteDailyEmployee'
              },
              {
                ID: 'Printer',
                ToolTip: 'Print',
                Emits: 'PrintSalarySlip',
                Style: 'filter: Saturate(0) Brightness(1.5) contrast(1);'
              },
              {
                ID: 'Employees',
                Visible: this.Access('HR_DailyEmployees_Read'),
                ToolTip: this.Translate('View DailyEmployee'),
                Emits: 'ViewDailyEmployee',
                Style: 'filter: Saturate(0) Brightness(1.5) contrast(1);'
              },
              {
                ID: 'Phone',
                Visible: this.Access('HR_DailyEmployees_Edit'),
                ToolTip: this.Translate('Assign New Device'),
                Emits: 'AssignNewDevice',
                DynamicVisiblity: {
                  Operator: 'NOT EQUAL',
                  FixedValue: null,
                  ValuePath: ['Devices']
                },
                Style: 'filter: Saturate(0) Brightness(1.5) contrast(1);'
              },
              {
                ID: 'Phone',
                Visible: this.Access('HR_DailyEmployees_Edit'),
                ToolTip: this.Translate('Assign Device'),
                Emits: 'AssignDevice',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: null,
                  ValuePath: ['Devices']
                },
                Style: 'filter: Saturate(0) Brightness(1.5) contrast(1); opacity: 0.3; cursor: normal;'
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Employee',
          this: this,
          Function: 'NewDailyEmployee',
          Icon: 'Add',
          Visible: this.Access('HR_DailyEmployees_Create')
        }
      ]
    }
  },
  methods: {
    ReadDepartments () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Departments').then(response => {
        this.Departments = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Departments'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewDailyEmployee () {
      if (this.Access('HR_DailyEmployees_Create')) {
        return
      }
      this.OpenDialog('HR_Employee_Dialog', this.Translate('New Daily Employee'), {}, { NoBackgroundClose: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.ReadDepartments()
      this.Get('HR_Employees', '?IsDaily=1').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading DailyEmployees'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditDailyEmployee (DailyEmployee :Employee) {
      if (!this.Access('HR_DailyEmployees_Read')) {
        return
      }
      this.OpenDialog('HR_Employee_Dialog', 'Edit Daily Employee (' + DailyEmployee.Name + ')', { Data: DailyEmployee, Editing: true }, {})
    },
    DeleteDailyEmployee (DailyEmployee :Employee) {
      if (!this.Access('HR_DailyEmployees_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_Employees', AddQuery: '/' + DailyEmployee.ID, Text: 'Are you sure you want to delete <strong>' + DailyEmployee.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.DailyEmployeeDeleted, AfterDialogFunctionValue: DailyEmployee.ImgUrl } as GeneralAcceptDialogData, {})
    },
    DailyEmployeeDeleted (ImgUrl :string) {
      if (ImgUrl) this.DeleteImage(ImgUrl)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewDailyEmployee':
          this.OpenDialog('HR_Employee_Dialog', this.Translate('New Daily Employee'), {}, { NoBackgroundClose: true })
          break
      }
    },
    SwapStatus (DailyEmployee :Employee) {
      if (!this.Access('HR_DailyEmployees_Remove')) {
        return
      }
      var CurrentStatus = DailyEmployee.Status
      if (CurrentStatus === 'Active') {
        this.OpenDialog('General_Accept_Dialog', this.Translate('Deactivate Daily Employee'), { PostMethod: 'PATCH', PostAPI: 'HR_Employees', PostData: { Status: 'Inactive' }, AddQuery: '/' + DailyEmployee.ID, Text: this.Translate('Are you sure you want to Deactivate <strong>' + DailyEmployee.Name + '</strong>'), ButtonClass: 'BGRed', ButtonText: this.Translate('Confirm'), Icon: 'Inactive' } as GeneralAcceptDialogData, {})
      } else {
        this.OpenDialog('General_Accept_Dialog', this.Translate('Activate Daily Employee'), { PostMethod: 'PATCH', PostAPI: 'HR_Employees', PostData: { Status: 'Active' }, AddQuery: '/' + DailyEmployee.ID, Text: this.Translate('Are you sure you want to Activate <strong>' + DailyEmployee.Name + '</strong>'), ButtonClass: 'BGRed', ButtonText: this.Translate('Confirm') } as GeneralAcceptDialogData, {})
      }
    },
    PrintSalarySlip (Transfer :InventoryTransfer) {
      this.OpenDialog('HR_PrintEmployeeSalarySlip_Dialog', 'Print Salary Slip', { Data: { ...Transfer, Type: 'InternalTransfer' } }, { NoBackgroundClose: true, FullWindow: true, background: 'none', header: false })
    },
    ViewDailyEmployee (DailyEmployee :Employee) {
      this.$router.push('/HR/DailyEmployee/' + DailyEmployee.ID)
    },
    AssignNewDevice (DailyEmployee :Employee) {
      if (!this.Access('HR_DailyEmployees_Edit')) {
        return
      }
      this.OpenDialog('HR_EmployeeDevice_Dialog', this.Translate(`${DailyEmployee.Name} Device`), { Data: DailyEmployee }, { NoBackgroundClose: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    AssignDevice (DailyEmployee :Employee) {
      if (!this.Access('HR_DailyEmployees_Edit')) {
        return
      }
      this.OpenDialog('HR_EmployeeDevice_Dialog', this.Translate(`${DailyEmployee.Name} Device`), { Data: DailyEmployee, Editing: true }, { NoBackgroundClose: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
