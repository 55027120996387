
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
// import router from './Apps/ALL/router/index'
const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  components: {},
  created () {
    const favicon = document.getElementById('FavIcon') as HTMLLinkElement
    favicon.href = this.$store.state.CookiesConfig.FavIco
    this.MEMWrite('GlobalSaving', [])
    this.MEMWrite('GlobalSavingDone', [])
    this.MEMWrite('GlobalSavingFailed', [])
    fetch('/Cookies.config.json')
      .then((CustomConfigRes) => {
        if (CustomConfigRes.ok) {
          CustomConfigRes.json()
            .then((CustomConfig) => {
              console.log('CustomConfig')
              this.$store.state.CookiesCustomConfig = CustomConfig
              this.AfterCustomConfig()
            })
        }
      })
      .catch((error) => {
        console.error(error)
        this.AfterCustomConfig()
      })
  },
  beforeMount () {
    this.TimeBeforeMounted()
  },
  mounted () {
    const favicon = document.getElementById('FavIcon') as HTMLLinkElement
    favicon.href = this.$store.state.CookiesConfig.FavIco
    setTimeout(() => {
      window.scrollTo(0, 1)
    }, 300)
  },
  methods: {
    AfterCustomConfig () {
      var LocalConfig = this.LocalRead('CookiesDefaultConfig')
      if (LocalConfig !== null) {
        this.$store.state.CookiesConfig = {
          ...this.$store.state.CookiesConfig,
          ...LocalConfig
        }
      }
      this.CookiesAuthorization()
    },
    ToolTipSence () {
      this.$store.state.ToolTipElement = document.querySelector('[ToolTip]:hover')
    }
  },
  computed: {
    TabHeaderShadower () {
      return this.$store.getters.CurrentTheme === 'light' ? 'rgba(0, 0, 0, 0.20)' : 'rgba(0, 0, 0, 0.17)'
    },
    Maintenance () {
      return this.$store.state.CookiesConfig.MaintenanceMode === 'true'
    },
    VersionChecker () {
      var ServerVersion = this.$store.state.CookiesVueServerVersion
      var CurrentVersion = this.$store.state.CookiesVersion
      if (!ServerVersion) return false
      if (parseInt(ServerVersion.split('.')[0]) > parseInt(CurrentVersion.split('.')[0])) return true
      if (parseInt(ServerVersion.split('.')[0]) < parseInt(CurrentVersion.split('.')[0])) return false
      if (parseInt(ServerVersion.split('.')[1]) > parseInt(CurrentVersion.split('.')[1])) return true
      if (parseInt(ServerVersion.split('.')[1]) < parseInt(CurrentVersion.split('.')[1])) return false
      if (parseInt(ServerVersion.split('.')[2]) > parseInt(CurrentVersion.split('.')[2])) return true
      return false
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "8c421e78": (_ctx.$store.getters.Direction),
  "a20d9ade": (_ctx.Theme.color3),
  "4d2810f2": (_ctx.Theme.colorBold),
  "ae07309c": (_ctx.$store.getters.DefaultTextAlign),
  "53edb36a": (_ctx.Theme.UserColors.main),
  "fa68eef4": (_ctx.Theme.UserColors.second),
  "28fd11d6": (_ctx.Theme.UserColors.third),
  "6d31ae2c": (_ctx.Theme.UserColors.fourth),
  "a20d9ae2": (_ctx.Theme.color1),
  "a20d9ae0": (_ctx.Theme.color2),
  "5f846ecb": (_ctx.Theme.BG1),
  "5f846ecc": (_ctx.Theme.BG2),
  "5f846ecd": (_ctx.Theme.BG3),
  "5f846ece": (_ctx.Theme.BG4),
  "5f846ecf": (_ctx.Theme.BG5),
  "5f846ed0": (_ctx.Theme.BG6),
  "5f846ed1": (_ctx.Theme.BG7),
  "5f846ed2": (_ctx.Theme.BG8),
  "72f7e12e": (_ctx.Theme.BG1Fixed),
  "6f8e2ff0": (_ctx.Theme.BG2Fixed),
  "6c247eb2": (_ctx.Theme.BG3Fixed),
  "68bacd74": (_ctx.Theme.BG4Fixed),
  "65511c36": (_ctx.Theme.BG5Fixed),
  "61e76af8": (_ctx.Theme.BG6Fixed),
  "5e7db9ba": (_ctx.Theme.BG7Fixed),
  "5b14087c": (_ctx.Theme.BG8Fixed),
  "5e8a228c": (_ctx.Theme.InputColor),
  "32d4a476": (_ctx.Theme.UserColors.Dimmed),
  "61fc3a3c": (_ctx.Theme.UserColors.Gradient),
  "d9784db8": (_ctx.Theme.Shade0),
  "d9784db6": (_ctx.Theme.Shade1),
  "d9784db4": (_ctx.Theme.Shade2),
  "d9784db2": (_ctx.Theme.Shade3),
  "d9784db0": (_ctx.Theme.Shade4),
  "7d2fe32f": (_ctx.Theme.BackBlur),
  "da7ec692": (_ctx.Theme.UserColors.main_25),
  "0f022005": (_ctx.Theme.UserColors.third_50),
  "f13a6144": (_ctx.Theme.Light3),
  "04e1605d": (_ctx.DirectionStyler.borderLeft),
  "d0bde6f4": (_ctx.DirectionStyler.borderRight),
  "23614253": (_ctx.TabHeaderShadower),
  "0444f290": (_ctx.CurrentBackgroundColor),
  "6e943334": (_ctx.DirectionStyler.first),
  "da419fc0": (_ctx.DirectionStyler.last),
  "16410c3f": (`url(${require("@/assets/elements/HorizontalEdgesMask.svg")})`)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__