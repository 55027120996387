
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'

import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/leaflet.js'

export default defineComponent({
  components: {
    LMap,
    LTileLayer,
    LMarker

  },
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      markerLatLng: null as any,
      GPSLongitude: null,
      GPSLatitude: null,
      clickedPlace: {
        latitude: null,
        longitude: null,
        address: null,
        countryCode: null
      },
      zoom: 5,
      OriginalData: {} as any,
      Saving: false,
      selectedFile: null,
      Loading: [] as Array<'Loading'>,
      Profiles: [],
      ErrorsVisibilty: false
    }
  },
  computed: {
    CheckGPSLongitude () {
      return this.GPSLongitude
    },
    CheckGPSLatitude () {
      return this.GPSLatitude
    },
    CheckmarkerLatLng () {
      return this.markerLatLng
    },
    DialogMapper () {
      return [
        { Path: 'Name', Res: 'D_Branch_Name' },
        { Path: 'Address', Res: 'D_Branch_Address', Default: '' },
        { Path: 'PostalCode', Res: 'D_Branch_PostalCode', Default: '' },
        { Path: 'PhoneNumber', Res: 'D_Branch_PhoneNumber', Default: '' },
        { Path: 'IsCustomIdentity', Res: 'D_Branch_CustomIdentity', Default: false },
        { Path: 'LegalName', Res: 'D_Branch_LegalName', Default: '' },
        { Path: 'ImgUrl', Res: 'D_Branch_ImgUrl', IsImage: true, Default: '' },
        { Path: 'GPSLongitude', Value: this.CheckGPSLongitude },
        { Path: 'GPSLatitude', Value: this.CheckGPSLatitude },
        { Path: 'Logo', Res: 'D_Branch_Logo', IsImage: true, Default: '' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    handleMapClick (e: { latlng: { lat: any; lng: any } }) {
      const { lat, lng } = e.latlng
      this.GPSLatitude = lat
      this.GPSLongitude = lng
      // Perform reverse geocoding to get place information
      // this.getPlaceInformation(lat, lng)
      this.markerLatLng = [lat, lng]
    },
    // getPlaceInformation (latitude: any, longitude: any) {
    // // Perform your reverse geocoding logic here.
    // // You can use a geocoding API like OpenCage Geocoding API, Google Maps Geocoding API, etc.

    //   // For example, using OpenCage Geocoding API:
    //   const apiKey = 'AIzaSyCfAyYZwyWK1VoismeLeMQTmOVtiGKtALg'
    //   const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`

    //   fetch(apiUrl)
    //     .then(response => response.json())
    //     .then(data => {
    //     // Extract relevant information from the response
    //       const placeInfo = data.results[0]
    //       const address = placeInfo.formatted
    //       const countryCode = placeInfo.components.country_code

    //       // Update the clickedPlace property with the information
    //       this.clickedPlace = {
    //         latitude,
    //         longitude,
    //         address,
    //         countryCode
    //       }
    //     })
    //     .catch(error => {
    //       console.error('Error fetching place information:', error)
    //     })
    // },
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Branches',
        OriginalData: this.OriginalData,
        PatchAPI: 'Branches',
        Editing: this.DialogData?.Editing,
        PatchQuery: this.DialogData?.Editing ? '/' + this.DialogData?.Data.ID : ''
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    LogoChanged (event :any) {
      var file = event.target.files[0]
      this.selectedFile = file
      if (!file) {
        return
      }
      this.PostImage(this.selectedFile).then((response :any) => {
        this.MEMWrite('D_Branch_Logo', response.secure_url)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Uploading Image'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  created () {
    if (this.DialogData?.Editing) {
      if (this.DialogData?.Data.GPSLatitude && this.DialogData?.Data.GPSLongitude) this.markerLatLng = [this.DialogData?.Data.GPSLatitude, this.DialogData?.Data.GPSLongitude]
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
    }
  },
  mounted () {
    if (this.DialogData?.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
      if (this.DialogData?.Editing && this.DialogData?.Data && this.DialogData?.Data.IsCustomIdentity) {
        this.MEMWrite('D_Branch_CustomIdentity', true)
      }
    }
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  }
})
